import React, { useState, useEffect } from 'react';
import MyImg from '../../../../accests/images/Frame.png'

import Dell from '../../../../accests/images/dell.png'

import PlusIcon from '../../../../accests/images/Plusicon.png'
import MinusIcon from '../../../../accests/images/Minus.png'
import { useDispatch} from 'react-redux';
import { checkOutfirstFunction } from 'store/FunctionSlice/CheckOutSlice';
const CheckOutFirstCard = ({ data, setTottal }) => {

    let [count, setCount] = useState(1)
    // let [tottalPrice, setTottalPrice] = useState(0) 

    const dispatch = useDispatch()
    const incriment = () => {
        let tottal = ++count
        setCount(tottal)
        dispatch(checkOutfirstFunction({ id: data.id, price: data.price }))
    }
    const dec = () => {
        if (count > 1) {
            let tottal = count - + 1
            setCount(tottal)
        }


    }
 
    useEffect(() => {
        // let tottal = data.totalPrice 
        // setTottalPrice(tottal)
        setTottal(1, data.price)

    }, [count,data.price])

    // useEffect(() => {

    // }, [])
 
    return (
        <div className=' w-full h-[206] md:w-[603px] md:h-[273px] mt-[12px] bg-white py-[12px] px-[8px] rounded-xl flex justify-center shadow-[0px_0px_6px_0px_#00000024]'>
            <div>
                <img src={MyImg} alt="" className='w-[100px] h-[160px]  md:w-[156px] md:h-[249px]' />
            </div>
            <div className=' ml-[17px]'>
                <div className=' h-[70px] w-[90px] md:w-[118px] '>
                    <div className='text-text-base font-bold block md:text-xl'>{data.title}</div>
                    <div className='text-xs md:text-base'>{data.condition}</div>
                    <div className='text-xs md:text-base'>Size: {data.size}</div>
                </div>
                <div className=' mt-10 h-[48px] w-[90px]  flex items-center md:mt-[98%] '>

                    <img onClick={incriment} className='cursor-pointer' src={PlusIcon} alt="" />
                    <div className='text-[18px] font-semibold md:text-[20px]'>{count}</div>
                    <img onClick={dec} className='cursor-pointer' src={MinusIcon} alt="" />
                </div>
            </div>

            <div className='w-[45.5%] flex justify-end'>
                <div className=' flex-col '>
                    <div className='h-12 w-12 rounded-lg border flex justify-center items-center float-right '>

                        <img src={Dell} className='w-[24px] h-[24px] ' alt="" />
                    </div>
                    <div className='mt-[62px] h-[48px] flex items-center text-[18px] md:text-[22px] font-semibold md:mt-[136px] w-full  justify-end float-right'> $ { data.totalPrice }.0</div>

                </div>
            </div>
        </div>
    );
}

export default CheckOutFirstCard;
