import Routes from "./Routes";
import ReduxStoreProvider from "redux/provider";
import { Provider } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import './style/SellerDashbord.css';
import { useState } from "react";
import { store } from "./redux";



function App() {
  const bodyStyle = {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(7, 0, 0, 0.18)",
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handle_modal = (a) => {
    setIsModalOpen(a);
  };
  return ( 

      <Provider store={store}>
        <Routes />
        <ToastContainer />
      </Provider>
  
  );
}

export default App;
