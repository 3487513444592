import React, { useState } from 'react';
import Ellipse from "../../../../accests/images/Ellipse.png";
const SellerDashbordAppointment = () => {
  const [appointment, setAppointment] = useState([{ title: 'Haircut & Beard', subTitle: 'with Dan Forrest', topic: 'TRDN Male Grooming Halesowen', img: Ellipse }, { title: 'Haircut & Beard', subTitle: 'with Dan Forrest', topic: 'TRDN Male Grooming Halesowen', img: Ellipse }, { title: 'Haircut & Beard', subTitle: 'with Dan Forrest', topic: 'TRDN Male Grooming Halesowen', img: Ellipse }])
  return (
    <div className='lg:flex mt-[16px] md:mt-[89px]'>
      <div className='upcoming-appointments-div'>
        <p className='appointments-detail'>UPCOMING APPOINTMENTS:</p>
        <span className='confirmed'>Confirmed</span>
        {
            appointment.map((data) => (
              <div className='appointment-div'>
          
              <div>
                <h2 className='haircut-beard'>{data.title}</h2>
                <p className='dan-forest'>{data.subTitle}</p>
                <div className="grooming-div">
                  <img src={data.img} alt="" />
                  <p className='grooming-content'>{data.topic}</p>
                </div>
              </div>
              <div className='appointment-time-div'>
                <p className='appointment-month'>September</p>
                <p className='appointment-date'>23</p>
                <p className='appointment-exact-time'>10:00 - 10:30</p>
              </div>
            </div>
          ))
          }
        {/* <div className='appointment-div'>
          
          <div>
            <h2 className='haircut-beard'>Haircut & Beard</h2>
            <p className='dan-forest'>with Dan Forrest</p>
            <div className="grooming-div">
              <img src={Ellipse} alt="" />
              <p className='grooming-content'>TRDN Male Grooming Halesowen</p>
            </div>
          </div>
          <div className='appointment-time-div'>
            <p className='appointment-month'>September</p>
            <p className='appointment-date'>23</p>
            <p className='appointment-exact-time'>10:00 - 10:30</p>
          </div>
        </div> */}

        {/* <div className='appointment-div'>
          <div>
            <h2 className='haircut-beard'>Haircut & Beard</h2>
            <p className='dan-forest'>with Dan Forrest</p>
            <div className="grooming-div">
              <img src={Ellipse} alt="" />
              <p className='grooming-content'>TRDN Male Grooming Halesowen</p>
            </div>
          </div>
          <div className='appointment-time-div'>
            <p className='appointment-month'>September</p>
            <p className='appointment-date'>23</p>
            <p className='appointment-exact-time'>10:00 - 10:30</p>
          </div>
        </div> */}

      </div>

      <div className='finished-appointments-div'>
        <p className='appointments-detail'>FINISHED APPOINTMENTS:</p>
        <span className='finished'>Finished</span>
        {
            appointment.map((data) => (
              <div className='appointment-div'>
          
              <div>
                <h2 className='haircut-beard'>{data.title}</h2>
                <p className='dan-forest'>{data.subTitle}</p>
                <div className="grooming-div">
                  <img src={data.img} alt="" />
                  <p className='grooming-content'>{data.topic}</p>
                </div>
              </div>
              <div className='appointment-time-div'>
                <p className='appointment-month'>September</p>
                <p className='appointment-date'>23</p>
                <p className='appointment-exact-time'>10:00 - 10:30</p>
              </div>
            </div>
          ))
          }
        {/* <div className='cutting-detail-div'>
          <div>
            <h2 className='haircut-beard'>Haircut & Beard</h2>
            <p className='dan-forest'>with Dan Forrest</p>
            <div className="grooming-div">
              <img src={Ellipse} alt="" />
              <p className='grooming-content'>TRDN Male Grooming Halesowen</p>
            </div>
          </div>
          <div className='appointment-time-div'>
            <p className='appointment-month'>September</p>
            <p className='appointment-date'>23</p>
            <p className='appointment-exact-time'>10:00 - 10:30</p>
          </div>
        </div> */}



        <button className='btn-book-again'>
          <span className='btn-book-again-content'>Book again</span>
        </button>
      </div>
    </div>
  );
}

export default SellerDashbordAppointment;
