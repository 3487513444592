import React from "react";
import Myorder from "../Myorders/Myorders";
import ProductCard from "../ProductsCard/ProductCard";
import SuggestedSeller from "../SuggestedSellers/SuggestedSeller";
import PromocCraousel from "../PromocCraousel/PromocCraousel";

export default function HeroSectios() {
  return (
    <div className="flex justify- mx-[31px] ">
      <div className="hidden lg:flex shadow-md pl-2 pr-3 ">
        <Myorder />
      </div>
      <div className="flex flex-col  lg:w-[38vw] xl:w-[45vw] mx-auto inner-shadow"> 
        <div className="md:mt-[30px]">
          <PromocCraousel />
        </div>
        <ProductCard />
      </div>
      <div className="hidden lg:flex shadow-lg pr-2 pl-3 w-[22vw]">
        <SuggestedSeller />
      </div>
    </div>
  );
}
