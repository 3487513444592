import React from 'react';
import check from '../../../../accests/images/check.png'
import Redcheck from '../../../../accests/images/ceck_red.png'


const CheckOutStepper = ({active}) => {
    return (
        <>
            <div className="flex justify-center  items-center text-[10px] md:text-[14px]">
                <div className="md:w-[413px] md:h-[78px] w-[286px] h-[75px]  flex justify-between items-center">
                    <div className="flex-col justify-center ">
                        <center>{active !== 0 ?  <img className='w-[24px] h-[24px]' src={Redcheck} alt="" /> : <img className='w-[24px] h-[24px]' src={check} alt="" /> } </center>
                        <center >Shopping Basket</center>
                    </div>
                    <hr className="border-[#777777] w-[33px]" /> 
                    <div className="flex-col justify-center ">
                    <center>{active > 1 ?  <img className='w-[24px] h-[24px]' src={Redcheck} alt="" /> : <img className='w-[24px] h-[24px]' src={check} alt="" /> } </center>
                        <center>Checkout</center>
                    </div>
                    <hr className="border-[#777777] w-[33px]" />

                    <div className="flex-col justify-center ">
                    <center>{active > 2 ?  <img className='w-[24px] h-[24px]' src={Redcheck} alt="" /> : <img className='w-[24px] h-[24px]' src={check} alt="" /> } </center>
                        <center>Payment
                            options</center>
                    </div>

                </div>


            </div>
        </>
    );
}

export default CheckOutStepper;
