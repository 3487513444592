import React, { useEffect, useState } from "react";
import HomeBlack from '../../../../accests/iconsSvg/homeIconBlack.svg';
import search from '../../../../accests/iconsSvg/searchIconBlack.svg';
import listIcon from '../../../../accests/iconsSvg/listIconBlack.svg';
import create from '../../../../accests/iconsSvg/plus-square-blackIcon.svg';
import message from '../../../../accests/iconsSvg/message-circle-blackIcon.svg';
import profile from '../../../../accests/iconsSvg/userIconBlack.svg';
import redHome from '../../../../accests/iconsSvg/home-redIcon.svg';
import redSearch from '../../../../accests/iconsSvg/redSearch.svg';
import redList from '../../../../accests/iconsSvg/redList.svg';
import redCreate from '../../../../accests/iconsSvg/redPlus-square.svg';
import redMessage from '../../../../accests/iconsSvg/message-circleRed.svg';
import redProfile from '../../../../accests/iconsSvg/redUser.svg';

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Iconsbar({ }) {
  const navigate = useNavigate();
  const [activeIcon, setActiveIcon] = useState("");

  const { user } = useSelector(state => state.auth);

  const handleIconClick = (iconName) => {
    setActiveIcon(iconName);
  };

  let servicesNavigationPath = '/';

  if (user?.accountType === 'personal') {
    servicesNavigationPath = '/buyerServices'
  } else if (user?.accountType === 'business') {
    servicesNavigationPath = '/startsetup'
  }


  return (
    <div className="py-5 md:py-0 bg-white z-10 w-full md:w-[80%] md:mx-auto lg:ml-36 md:my-[31px] md:relative fixed bottom-0">
      <div className="w-[90%] md:w-[80vw] lg:w-[35vw] xl:w-[40vw] mx-auto">
        <div className="flex justify-between ml-3">
          <div onClick={() => { handleIconClick('home'); navigate('/'); }} className="cursor-pointer">
            <img src={activeIcon === 'home' ? redHome : HomeBlack} alt="Home" />
          </div>
          <div onClick={() => { handleIconClick('search'); navigate('/'); }} className="cursor-pointer">
            <img src={activeIcon === 'search' ? redSearch : search} alt="Search" />
          </div>
          {/* /buyerServices */}
          <div onClick={() => { handleIconClick('list'); navigate(servicesNavigationPath); }} className="cursor-pointer">
            <img src={activeIcon === 'list' ? redList : listIcon} alt="List" />
          </div>
          <div onClick={() => { handleIconClick('create'); navigate('/categories'); }} className="cursor-pointer">
            <img src={activeIcon === 'create' ? redCreate : create} alt="Create" />
          </div>

          <div onClick={() => { handleIconClick('message'); navigate('/messenger') }} className="cursor-pointer">
            <img src={activeIcon === 'message' ? redMessage : message} alt="Message" />
          </div>
          <div onClick={() => { handleIconClick('profile'); navigate('/profileDetails'); }} className="cursor-pointer">
            <img src={activeIcon === 'profile' ? redProfile : profile} alt="Profile" />
          </div>
        </div>
      </div>
    </div>
  );
}
