// import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";


// const baseUrl = 'https://7d78-2400-adc7-15f-5200-3121-5477-a144-8d37.ngrok-free.app/api'

// export const forgotThunk = createAsyncThunk(
//     "functions/forgotThunk",
//     async (data, thunkAPI) => {
//       try {
//         console.log("data", data);
//         const response = await axios.post(
//           `${baseUrl}/resendOtp`,
//           {
//             "email":"wishawan@gmail.com" 
//             // "otp": "982751"
//         },
//           {
//             headers: {
//               "Content-Type": "application/json",
//             },
            
//           }
//         )
  
//         console.log('otp response', response.data);
      
  
//         if (response) {
//           console.log("response send in callback", response);
//           return response;
//         }
  
//         return thunkAPI.rejectWithValue({
//           message: "Request Failed",
//         });
//       } catch (error) {
//         console.log("errr", error);
  
//         return thunkAPI.rejectWithValue({
//           message: error.message,
//         });
//       }
//     }
//   );



// const ForgotSlices = createSlice({
//     name: "functions",
//     initialState: {
//         functions: [],
//         loading: false,
//         email: null,
//         userForget: {},
//         phoneNumber: null,
//         error: null,
//     },
//     reducers: {
//         firstFunction(state, action) {
//             console.log('kia hall ahai9', action.payload);
//         },

//         emailHandle(state, action) {
//             state.email = action.payload
//             console.log('check phone', state.email);

//         },
//         phoneHandle(state, action) {
//             state.phoneNumber = action.payload
//             console.log('check phone', action.payload);
//         },

//         ForgetHandle(state, action) {
//             state.userForget = action.payload
//             console.log('check user', state.userForget);
//         }

//     },

// })

// export default ForgotSlices;

// export const {
//     firstFunction,
//     emailHandle,
//     phoneHandle,
//     ForgetHandle
// } = ForgotSlices.actions;



import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = 'https://7d78-2400-adc7-15f-5200-3121-5477-a144-8d37.ngrok-free.app/api';

export const forgotThunk = createAsyncThunk(
  "functions/forgotThunk",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseUrl}/resendOtp`,
        {
          "email": "wishawan@gmail.com",
          // "otp": "982751"
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );


      if (response) {
        return response.data; // Return the data instead of the entire response
      }

      return thunkAPI.rejectWithValue({
        message: "Request Failed",
      });
    } catch (error) {

      return thunkAPI.rejectWithValue({
        message: error.message,
      });
    }
  }
);

const ForgotSlices = createSlice({
  name: "functions",
  initialState: {
    functions: [],
    loading: false,
    email: null,
    userForget: {},
    phoneNumber: null,
    error: null,
  },
  reducers: {
    firstFunction(state, action) {
    },

    emailHandle(state, action) {
      state.email = action.payload;
    },

    phoneHandle(state, action) {
      state.phoneNumber = action.payload;
    },

    ForgetHandle(state, action) {
      state.userForget = action.payload;
    },
  },
});

export default ForgotSlices;

export const {
  firstFunction,
  emailHandle,
  phoneHandle,
  ForgetHandle,
} = ForgotSlices.actions;