
import React from 'react';
import { Button } from '@material-tailwind/react';

const SellerDashboardPromoCart = (props) => {
    const cardStar = 5
    return (
        <div className='max-w-[1440px] m-auto '>
        <div className="product-box">
            <div className='absolute mt-2 ml-[13%] lg:ml-[9%]'><p className='m-auto w-[70px] h-[27px] text-white text-xs font-semibold bg-[#BF1017] flex justify-center items-center rounded-md'>ProMo</p></div>
            <img src={props.itemCard.image} className="w-full  rounded-xl" alt="" /> 
            <div className='w-full flex gap-x-4 mt-2'>
                <Button className='shadow-none text-xl text-[#00B49E] font-semibold border border-[#00B49E] h-[32px] w-1/2 flex items-center justify-center'>Promote</Button>
                <Button className='shadow-none text-xl text-[#BF1017] font-semibold border border-[#BF1017] h-[32px] w-1/2 flex items-center justify-center'>Edit</Button>
            </div>
        </div>
        </div>
    );
}

export default SellerDashboardPromoCart;
