import React  from 'react'
import HeroSectios from '../Components/HeroSection/HeroSectios';

function Home() {
 
  return (
    <>
      <HeroSectios/>
    </>
  )
}

export default Home;