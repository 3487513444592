import react from 'react'
import item1 from "../../../../../accests/images/item1.png"
import item2 from "../../../../../accests/images/item2.png";
import item3 from "../../../../../accests/images/item3.png";
import item4 from "../../../../../accests/images/item4.png";
import star from "../../../../../accests/images/star.png";
import SellerDashboardPromoCart from '../../SellerDashboardPromoCart/SellerDashboardPromoCart';

const Mypromos=()=>{

    const itemCard = [
        { 
            image: item1,
            title: "Wite T-shirt",
            rating: "4.0",
            star: star,
            price: "$20.00",
        },
        {
            image: item2,
            title: "Limited edition",
            rating: "4.0",
            star: star,
            price: "$10.00",
        },

        {
            image: item3,
            title: "Limited edition",
            rating: "4.0",
            star: star,
            price: "$50.00",
        },
        {
            image: item4,
            title: "Wite T-shirt",
            rating: "4.0",
            star: star,
            price: "$50.00",
        },
        {
            image: item1,
            title: "Wite T-shirt",
            rating: "3.0",
            star: star,
            price: "$50.00",
        },
        {
            image: item2,
            title: "Wite T-shirt",
            rating: "2.0",
            star: star,
            price: "$50.00",
        },

        {
            image: item3,
            title: "Wite T-shirt",
            rating: "5.0",
            star: star,
            price: "$50.00",
        },
        {
            image: item4,
            title: "Wite T-shirt",
            rating: "4.0",
            star: star,
            price: "$50.00",
        },
    ];

    return <div>
 <div className="products-section">
                            {itemCard.map((itemCard, index) => (

                                <SellerDashboardPromoCart key={index} itemCard={itemCard} />
                            ))}
                        </div>
    </div>
}
export default Mypromos