import React from "react";
import AuthLayoutLeftSideBar from "./LeftSideBar";
import AuthLayoutFooter from "./Footer";

function AuthLayout({ children, isFooter = true }) {
  return (
    <>
      <div className="sm:flex justify-between">
        <AuthLayoutLeftSideBar />
        <div className="lg:w-[45%] sm:mt-[10vh] sm:w-1/2  rounded-lg p-3 sm:p-6 md:py-12 md:px-7 w-full flex md:justify-start justify-center items-center">
          <div className=" max-w-[350px] xl:max-w-[450px]  w-full ">
        {children}
        </div>
        </div>
      </div>
      {isFooter && <AuthLayoutFooter />}
    </>
  );
}

export default AuthLayout;
