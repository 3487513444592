import React,{ useState } from 'react';
import profile from "../../../../accests/images/profile.png";
import { useNavigate } from 'react-router-dom';
import { Button } from '@material-tailwind/react';
import MyPromo from '../../../../accests/iconsSvg/MyPromo.svg'
import MyPromoRed from '../../../../accests/iconsSvg/MyPromoRed.svg'
import MyStoreRed from '../../../../accests/iconsSvg/My store icon.svg'
import MyStore from '../../../../accests/iconsSvg/MyStore.svg'
import MySaved from '../../../../accests/iconsSvg/MySaved.svg'
import MySavedRed from '../../../../accests/iconsSvg/MySavedRed.svg'
import SellerStoreProfile from 'pages/Frontend/SellerDashbord';
import Mypromos from '../SellerDashboardTabs/SellerDashboardMypromos';


const SellerDashbordProfile = ({ active, user }) => {
    const navigate = useNavigate();
    const [isActive,setisActive]=useState(1);
    const [data, setData] = useState([{ title: 'My Orders', tottal: '50' }, { title: 'Posts', tottal: '0' }, { title: 'Supporters', tottal: '7' }, { title: 'Supporting', tottal: '13' }])
    const ratStar = 5
    const shortParagraph = user.discription.slice(0, 30)

   

    return (
        <div className=''>
            <div className="profile-box"> 
                <img
                    src={profile}
                    alt=""
                    className="profile-picture"
                />
                <div className="profile-status">
                    <div className="profile-rating-box">
                        <div className="sm:mt-0 mt-2">
                            <h1 className="profile-name">
                                {user.name}
                            </h1>
                            <div className="rating-box cursor-pointer " onClick={()=>navigate('/review')}>
                                <span className="rating-number ">{user.rating}.0 ({user.tottalReview}) </span>
                                {[...Array(ratStar)].map((_, i) => (
                                    <span key={i} className="star ">
                                        {i < user.rating ? '★' : '☆'}
                                    </span>
                                ))}

                            </div>
                        </div>
                        <div>
                            <button onClick={() => navigate('/editProfile')} className="edit-btn">
                                Edit Profile
                            </button>
                        </div>
                        <div className="sm-edit-btn">
                            <button className="edit-btn-text">
                                Edit
                            </button>
                        </div>
                    </div>
                    <div className="order-status flex justify-between">
                {
                    data.map((data, i) => {
                        return (
                            <div key={i}>
                                {
                                    !active ?
                                        <div className=''>
                                            {
                                                i !== 1 ?
                                                    <div className={`${i === 0 ? 'sm-number-of-orders-box' : 'sm-supporter-box'}`}>
                                                        <p className="sm-number-of-order1">{i === 0 ? user.order : i === 1 ? user.post : i === 2 ? user.sporting : i === 3 ? user.sports : ''}</p>
                                                        <p className="sm-status-title1">{data.title}</p>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                        </div>
                                        :
                                        <div>

                                            {/* <div className={`${i === 0 ? 'sm-number-of-orders-box mx-2' : 'sm-supporter-box mx-2'}`}>
                                                <p className="sm-number-of-order">{i === 0 ? user.order : i === 1 ? user.post : i === 2 ? user.sporting : i === 3 ? user.sports : ''}</p>
                                                <p className="sm-status-title">{data.title}</p>
                                            </div> */}
                                            <div className="sm-supporter-box mx-2">
                                                <p className="sm-number-of-order">{i === 0 ? user.order : i === 1 ? user.post : i === 2 ? user.sporting : i === 3 ? user.sports : ''}</p>
                                                <p className="sm-status-title">{data.title}</p>
                                            </div>

                                        </div>
                                }

                            </div>
                        )
                    })
                }

            </div>
                    <div className="sm-seller-review">
                        {shortParagraph} ...
                    </div>
                    <div className="lg-screen-seller-review">
                        {user.discription}
                    </div>
                </div>
            </div>
            {/* oder and post status */}

            <div className='flex justify-evenly border-y border-[#D9D9D9] md:mt-8'>
<Button className={`${isActive === 1 ? 'text-[#BF1017]':'text-black'} normal-case shadow-none  flex items-center text-lg font-medium`} onClick={()=>setisActive(1)}> <img src={`${isActive ===1 ? MyStoreRed : MyStore}`} alt='' className='mx-2'/> My store</Button>
<Button className={`${isActive === 2 ? 'text-[#BF1017]':'text-black'} normal-case shadow-none  flex items-center text-lg font-medium`} onClick={()=>setisActive(2)}> <img src={`${ isActive=== 2 ? MyPromoRed : MyPromo}`} alt='' className='mx-2'/> My ProMos</Button>
<Button className={`${isActive === 3 ? 'text-[#BF1017]':'text-black'} normal-case shadow-none  flex items-center text-lg font-medium`} onClick={()=>setisActive(3)}><img src={`${isActive ===3 ? MySavedRed : MySaved}`} alt='' className='mx-2'/>My Saved</Button>
            </div>
            <div className="sm-order-status">
                {
                    data.map((data, i) => {
                        return (
                            <div key={i}>
                                {
                                    !active ?
                                        <div>
                                            {
                                                i !== 1 ?
                                                    <div className={`${i === 0 ? 'sm-number-of-orders-box mx-2' : 'sm-supporter-box mx-2'}`}>
                                                        <p className="sm-number-of-order">{i === 0 ? user.order : i === 1 ? user.post : i === 2 ? user.sporting : i === 3 ? user.sports : ''}</p>
                                                        <p className="sm-status-title">{data.title}</p>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                        </div>
                                        :
                                        <div>

                                            {/* <div className={`${i === 0 ? 'sm-number-of-orders-box mx-2' : 'sm-supporter-box mx-2'}`}>
                                                <p className="sm-number-of-order">{i === 0 ? user.order : i === 1 ? user.post : i === 2 ? user.sporting : i === 3 ? user.sports : ''}</p>
                                                <p className="sm-status-title">{data.title}</p>
                                            </div> */}
                                            <div className="sm-supporter-box mx-2">
                                                <p className="sm-number-of-order">{i === 0 ? user.order : i === 1 ? user.post : i === 2 ? user.sporting : i === 3 ? user.sports : ''}</p>
                                                <p className="sm-status-title">{data.title}</p>
                                            </div>

                                        </div>
                                }

                            </div>
                        )
                    })
                }

            </div>

            {
                isActive === 2 ? <Mypromos/> : ''

            }
             {/* {
                 isActive === 1 ? <Mypromos/> : isActive === 2 ? <Mypromos/> : isActive === 3 ? <Mypromos/> : ''

            } */}
        </div>
    );
}

export default SellerDashbordProfile;
