import React, { useState } from 'react';
import messageIcon from '../../../accests/images/message_icon.png'
import messageGroup from '../../../accests/images/message_group.png'
import messageHistory from '../../../accests/images/message_history.png'
import profile1 from '../../../accests/images/message_photo.png'
import profile2 from '../../../accests/images/Sseller.png'
import messageRed from '../../../accests/images/message_red.png'
import messageGroupRed from '../../../accests/images/message_group_red.png'
import messageHistoryRed from '../../../accests/images/message_history_red.png'
import MessageCard from '../Components/MessageCard/MessageCard';
import MessengerServices from '../Components/MessengerServices/MessengerServices';
import MessengerChatBox from '../Components/MessengerChatBox/MessengerChatBox';
import MessengerChatBoxTwo from '../Components/MessengerChatBoxTwo/MessengerChatBoxTwo';
 
const Messenger = () => {
    const switchButton = [{ title: 'orders', img: messageGroup, actImg: messageRed }, { title: 'Groups', img: messageIcon, actImg: messageGroupRed }, { title: 'Servives', img: messageHistory, actImg: messageHistoryRed }]
    const [user, setUser] = useState([{ name: 'Chirs Howl', meesage: 'Yes, of course!', img: profile2 }, { name: 'Chirs Howl', meesage: 'Yes, of course!', img: profile1 }, { name: 'Chirs Howl', meesage: 'Yes, of course!', img: profile2 }, { name: 'Chirs Howl', meesage: 'Yes, of course!', img: profile1 }, { name: 'Chirs Howl', meesage: 'Yes, of course!', img: profile2 }, { name: 'Chirs Howl', meesage: 'Yes, of course!', img: profile1 }, { name: 'Chirs Howl', meesage: 'Yes, of course!', img: profile2 }, { name: 'Chirs Howl', meesage: 'Yes, of course!', img: profile1 }])
    const [switchButtonActive, setSwitchButtonActive] = useState(0)
    const messengerServiceCard = [{ img: profile2, name: 'Clay Mcintyre' }, { img: profile2, name: 'Clay Mcintyre' }, { img: profile2, name: 'Clay Mcintyre' }, { img: profile2, name: 'Clay Mcintyre' }]
    return (
        <div className='flex p-2 gap-[2%] pt-10'>
            <div className={` lg:w-[25%] md:w-[35%] sm:w-[50%] rounded-[8px] w-[0px] ${switchButtonActive !== 2 && 'shadow-md shadow-gray-300'}  m-3 mt-0 px-2`}>
                <div className='grid grid-cols-3 gap-3'>
                    {
                        switchButton.map((item, i) => {
                            return (
                                <div key={i} onClick={() => setSwitchButtonActive(i)} className='  text-center py-5 rounded-md shadow-md shadow-gray-300 cursor-pointer' >
                                    <img className='relative m-auto w-[25px]' src={i === switchButtonActive ? item.actImg : item.img} alt="" />
                                    <p className='font-bold'>{item.title}</p>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    switchButtonActive === 2 ? <MessengerServices messengerServiceCard={messengerServiceCard} /> : <MessageCard switchButtonActive={switchButtonActive} user={user} />
                }

                {/* <div className='h-[80vh] mt-5 overflow-y-auto'>
                    <div className='mb-3'>
                        <div className='flex justify-between '>
                            <div className='flex w-[85%]  items-center shadow-md shadow-gray-300 py-3 px-3 rounded-md'>< img className='w-[15px] h-[15px]' src={Search} alt="" /><input className='outline-none ml-2 w-[90%] text-sm' type="text" placeholder='Search' /></div>
                            <div className=' flex items-center mr-3 justify-center'> <img className='W-[20PX] h-[20px]' src={switchButtonActive == 0 ? chat : friend} alt="" /></div>
                        </div>
                    </div>
                    <div className=''>
                        { 
                            user.map((data) => { 
                                return (
                                    <div className='flex shadow-md p-2 mb-3 rounded-md shadow-gray-300 justify-between'>
                                        <div className='flex'>
                                            <img src={data.img} alt="" />

                                            <div className='ml-2'>
                                                <p className='font-bold '>{data.name}</p>
                                                <p className=' text-[14px] '>{data.meesage}</p>
                                            </div>
                                        </div>
                                        <div className=' mt-2 mr-2 '>
                                            <p className='text-[10px] text-gray-400 font-semibold'>14:47 pm</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className='flex shadow-md p-2 mb-3 rounded-md shadow-gray-300 justify-between'>
                            <div className='flex'>
                                <img className='rounded-full' src={profile1} alt="" />

                                <div className='ml-2'>
                                    <p className='font-bold '>Chirs Howl</p>
                                    <p className=' text-[14px] '>Yes, of course!</p>
                                </div>
                            </div>
                            <div className=' mt-2 mr-2 '>
                                <p className='text-[10px] text-gray-400 font-semibold'>14:47 pm</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className='w-[70%]'>
                {
                  switchButtonActive === 2 ?
                  <MessengerChatBoxTwo/>
                  :
                <MessengerChatBox />

                }
            </div>
        </div>
    );
}

export default Messenger;
