import React from 'react';
import Dropdown from '../ToggleDropdown/ToggleDropdown';
import arrow from "../../../../accests/images/arrow.png";
import search from "../../../../accests/images/search.png";
import Slider from "../../../../accests/iconsSvg/sliders.svg"
const SellerDashbordSlection = ({active}) => {
  return (
//     <div className="filters-div">
//     <Dropdown
//         id='options'
//         arrow={arrow}
//         btnHeading={"Category"}
//         option1={"Woman's clothes"}
//         option2={"Man's clothes"}
//         option3={"Kid's clothes"}
//         option4={"Footwear"}
//     />
//     <button className="search-filter">
//         Search
//     </button>
//     <Dropdown
//         id='options2'
//         btnHeading={"Filter"}
//         option1={"Best Match"}
//         option2={"Lowest Price and Postage"}
//         option3={"Highest Price and Postage"}
//         option4={"Newly List"}
//     />
// </div>

<div className={`flex ${!active && 'lg:flex md:flex sm:hidden hidden max-w-[1440px] m-auto'} `}>
<div className="w-full flex justify-center md:p-3 max-w-[1440px] m-auto">
  <div className='w-[445px] h-[42px] bg-[#ECECEC] p-[8px] flex justify-center items-center rounded-[8px] text-center relative'>
    <Dropdown
      btnHeading="Category"
      arrow={arrow}
      id="categoryDropdown"
      option1="Woman's clothes"
      option2="Man's clothes"
      option3="Kid's clothes"
      option4="Footwear"
    />

  </div>
  <div className='w-[445px] h-[42px] bg-[#ECECEC]  ml-[14px] md:mx-[14px] p-[8px] flex justify-center items-center rounded-[8px] '>
    <img src={search} alt="" className=' ml-[6px] mr-1' />
    Search
  </div>
  <div className='w-[445px] h-[42px] bg-[#ECECEC] p-[8px] hidden md:flex justify-center items-center rounded-[8px] text-center relative'>
  <img src={Slider} alt="" className=' ml-[6px]' />
    <Dropdown
      btnHeading="Filter"
  
      id="filterDropdown"
      option1="Best Match"
      option2="Lowest Price and Postage "
      option3="Highest Price and Postage"
      option4="Newly List"
    />

  </div>
</div>
</div>
  );
}

export default SellerDashbordSlection;
