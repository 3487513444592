import React from "react";
import leftArrow from "../../../../accests/icons/leftArrow.svg";

function AuthLayoutLeftSideBar() {
  return (
    <div className="sm:flex items-center sm:mb-0 mb-5 sm:h-[88vh] sm:w-[50%] ">
      
      <div className="sm:fixed sm:w-[50%] w-full md:pr-12 lg:pr-0 text-center sm:flex sm:justify-center sm:text-left mt-12 sm:mt-0">
        <div className="sm::w-[90%] sm:block flex lg:w-[70%] sm:pl-6 pl-0  justify-between">


        <button className=" backButtonForMobile  pr-4 ml-[1%]  w-[33%]"> 
      {/* <img src={leftArrow} alt="Back" /> */}
      {/* <span className="text-[16px] font-medium">Back</span> */}
      </button>
      


            <div className="header-div sm:w-full w-[70%]  sm:px-0  px-2">
          <h1 className="font-bold lg:text-6xl sm:text-4xl  text-[30px]  text-[#BF1017]">
            SellOut
          </h1>
          
   
          <p className="leading-relaxed mt-3 lg:mt-6 selloutTagLine md:text-[22px]  sm:text-xl sm:w-[90%]  text-[20px] sm:font-normal font-[500]">
          SellOut helps you buy & sell and provide services.
          </p>  
          </div>


          <div className="empty-div"></div>

        </div>
      </div>
    </div>
  );
}

export default AuthLayoutLeftSideBar;
