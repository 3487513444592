import React, { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Frontend from '../pages/Frontend'

import AuthLayout from "Components/Layouts/AuthLayout";
import DashboardLayout from "Components/Layouts/DashboardLayout";
import HomeJourney from "pages/Frontend/ProfileDetails/Reviews/HomeJourny";

import { PublicRoute } from "./PublicRoutes";
import { PrivateRoute } from "./PrivateRoutes";
import DefaultLayout from "Components/Layouts/DefaultLayout";
import SelectAccountLayout from "Components/Layouts/SelectAccountLayout";
// import About from "pages/Frontend/About/About";
// import CartScreen from "pages/Frontend/CartScreen/CartScreen";
// import Settings from "pages/Frontend/Settings/Settings";
// import CheckoutCompleted from "pages/Frontend/CheckoutCompleted";
// import Messenger from "pages/Frontend/Messenger/Messenger";
// import CheckOut from "pages/Frontend/CheckOut/CheckOut";
// import FourZeroFour from "pages/Frontend/404";
// import CheckOutStepTwo from "pages/Frontend/Components/CheckOutStepTwo/CheckOutStepTwo";
const BusinessUserProfile = lazy(() => import('pages/Frontend/Business_User_Profile'));
const BusinessProfile = lazy(() => import('pages/Frontend/Business_Profile'));
const CheckOutStepThree = lazy(() => import('pages/Frontend/Components/CheckOutStepThree/CheckOutStepThree'));
const CheckOutStepTwo = lazy(() => import('pages/Frontend/Components/CheckOutStepTwo/CheckOutStepTwo'));
const FourZeroFour = lazy(() => import('pages/Frontend/404'));
const About = lazy(() => import('pages/Frontend/About/About'));
const CartScreen = lazy(() => import('pages/Frontend/CartScreen/CartScreen'));
const Settings = lazy(() => import('pages/Frontend/Settings/Settings'));
const MoreInformation = lazy(() => import('pages/Frontend/Settings/MoreInformation'));
const NotificationSetting = lazy(() => import('pages/Frontend/Settings/NotificationSetting'));
const EmailNotification = lazy(() => import('pages/Frontend/Settings/EmailNotification'));
const PrivacySetting = lazy(() => import('pages/Frontend/Settings/PrivacySetting'));
const PaymentOption = lazy(() => import('pages/Frontend/Settings/PaymentOption'));
const OrderReceipts = lazy(() => import('pages/Frontend/Settings/OrderReceipts'));
const Balance = lazy(() => import('pages/Frontend/Settings/Balance'));
const Postage = lazy(() => import('pages/Frontend/Settings/Postage'));
const Contacts = lazy(() => import('pages/Frontend/Settings/Contacts'));
const Security = lazy(() => import('pages/Frontend/Settings/Security'));
const UserAccountSetting = lazy(() => import('pages/Frontend/Settings/UserAccountSetting'));
const Notification = lazy(() => import('pages/Frontend/Notification'));
const CheckoutCompleted = lazy(() => import('pages/Frontend/CheckoutCompleted'));
const Messenger = lazy(() => import('pages/Frontend/Messenger/Messenger'));
const CheckOut = lazy(() => import('pages/Frontend/CheckOut/CheckOut'));
const Signup = lazy(() => import('pages/Authentication/Signup'));
const Login = lazy(() => import('pages/Authentication/Signin'));
const ForgotPassword = lazy(() => import('pages/Authentication/ForgotPassword'));
const EnterCode = lazy(() => import('pages/Authentication/EnterCode'));
const SelectAccount = lazy(() => import("pages/Authentication/SelectAccount"));
const BusinessForm = lazy(() => import('pages/Authentication/BusinessForm'));
const EnterBirthday = lazy(() => import("pages/Authentication/Enterbirthday"));
const PhotoVerification = lazy(() => import("pages/Authentication/PhotoVerification"));
const EnableLocation = lazy(() => import("pages/Authentication/EnableLocation"));
const PrivacyPolicy = lazy(() => import("pages/Frontend/PrivacyPolicy"));

const StartSetup = lazy(() => import("pages/Frontend/SetupService/StartSetup"));
const BuisnessInfoForm = lazy(() => import("pages/Frontend/SetupService/BuisnessInfoForm"));
const DataProtection = lazy(() => import("pages/Frontend/SetupService/DataProtection"));
const FindClient = lazy(() => import("pages/Frontend/SetupService/FindClient"));
const WorkSpace = lazy(() => import("pages/Frontend/SetupService/WorkSpace"));
const EnterAddress = lazy(() => import("pages/Frontend/SetupService/EnterAddress"));
const ConfirmYourAddress = lazy(() => import("pages/Frontend/SetupService/ConfirmYourAddress"));
const PinLocation = lazy(() => import("pages/Frontend/SetupService/PinLocation"));
const TravelFee = lazy(() => import("pages/Frontend/SetupService/TravelFee"));
// const FeePolicy = lazy(() => import("pages/Frontend/SetupService/FeePolicy"));
const TellMore = lazy(() => import("pages/Frontend/SetupService/TellMore"));
const BusinessHours = lazy(() => import("pages/Frontend/SetupService/BusinessHours"));
const AddServices = lazy(() => import("pages/Frontend/SetupService/AddServices"));
const SavedServices = lazy(() => import("pages/Frontend/SetupService/SavedServices"));
const AddPhoto = lazy(() => import("pages/Frontend/SetupService/AddPhoto"));
const Congratulation = lazy(() => import("pages/Frontend/SetupService/Congratulation"));




const Home = lazy(() => import("pages/Frontend/Home"));
const Categories = lazy(() => import("pages/Frontend/Categories"));
// const AddPost = lazy(() => import("pages/Frontend/AddPost"));
// const EditProfile = lazy(() => import("pages/Frontend/EditProfile"));
const SellerStoreProfile = lazy(() => import("pages/Frontend/SellerDashbord"));
const EditProfileForm = lazy(() => import("pages/Frontend/EditProfileForm/EditProfileForm"));
const StartListing = lazy(() => import("pages/Frontend/StartListing"));
// const Test = lazy(() => import("pages/Frontend/Test"));
const BuyerServices = lazy(() => import("pages/Frontend/BuyerServices"));
const Appointments = lazy(() => import("pages/Frontend/BuyerServices/Appointments"));
const BookAppointment = lazy(() => import("pages/Frontend/BookAppointment"));
const HowPromoteworks = lazy(() => import("pages/Frontend/PromoteProduct/HowPromoteworks"));
const ChooseYourPromotion = lazy(() => import("pages/Frontend/PromoteProduct/ChooseYourPromotion"));
const ReviewOrder = lazy(() => import("pages/Frontend/PromoteProduct/ReviewOrder"));
const PromotionInsights = lazy(() => import("pages/Frontend/PromoteProduct/PromotionInsights"));
const SellerAboutUs = lazy(() => import("pages/Frontend/SellerProfile/AboutUs"));
const AboutUs = lazy(() => import("pages/Frontend/Settings/AboutUs"));
const BuisnessProfile = lazy(() => import("pages/Frontend/BuisnessProfile"));
const Supporters = lazy(() => import("pages/Frontend/Supporters"));
const MyOrders = lazy(() => import("pages/Frontend/MyOrders"));
const TrackingNumber = lazy(() => import("pages/Frontend/MyOrders/TrackingNumber"));
const CustomerFeedback = lazy(() => import("pages/Frontend/MyOrders/CustomerFeedback"));
const OrderDetail = lazy(() => import("pages/Frontend/MyOrders/OrderDetail"));
const Invoice = lazy(() => import("pages/Frontend/MyOrders/Invoice"));
const CancleOrder = lazy(() => import("pages/Frontend/MyOrders/CancleOrder"));
const ReportBuyers = lazy(() => import("pages/Frontend/MyOrders/ReportBuyers"));
const PaymentDetail = lazy(() => import("pages/Frontend/MyOrders/PaymentDetail"));
const RefundBuyers = lazy(() => import("pages/Frontend/MyOrders/RefundBuyers"));
const SellerProf=lazy(()=>import ("pages/Frontend/Seller_Profile"))



function RoutesComponent() {
  return (
    <div>
      <BrowserRouter>
        <Routes>

          {/* *************** Public Routes *************** */}
          <Route
            path="/signup"
            element={<PublicRoute component={Signup} layout={AuthLayout} />}
          />
          <Route
            path="/login"
            element={<PublicRoute component={Login} layout={AuthLayout} />}
          />
          <Route
            path="/forgotPassword"
            element={<PublicRoute component={ForgotPassword} layout={AuthLayout} />}
          />

          <Route
            path="/selectAccount"
            element={<PublicRoute component={SelectAccount} layout={DefaultLayout} />}
          />

          <Route
            path="/dob"
            element={<PublicRoute component={EnterBirthday} layout={SelectAccountLayout} showHeader={true} />}
          />

          <Route
            path="/business-info"
            element={<PublicRoute component={BusinessForm} layout={SelectAccountLayout} showHeader={true} />}
          />

          <Route
            path="/notification"
            element={<PublicRoute component={Notification} layout={DashboardLayout} navbar={false} />}
          />

          <Route path='/verifyOTP' element={<EnterCode />} />
          {/* <Route path='/selectAccount' element={<SelectAccount />} /> */}
          {/* <Route path='/dob' element={<EnterBirthday />} /> */}
          {/* <Route path='/business-info' element={<BusinessForm />} /> */}
          <Route path='/photoVerification' element={<PhotoVerification />} />
          <Route path='/enableLocation' element={<EnableLocation />} />

          <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
          {/* <Route path='/StartSetup' element={<StartSetup />} />
          <Route path='/dataprotection' element={<DataProtection />} /> */}
          <Route path='/Congratulation' element={<Congratulation />} />

          <Route path='/about' element={<About />} />
          <Route path='/cart' element={<CartScreen />} />
          <Route path='/settings' element={<Settings />} />
          {/* <Route path='/notification' element={<Notification />} /> */}
          <Route path='/checkoutcompleted' element={<CheckoutCompleted />} />
          <Route path='/CheckOut' element={<CheckOut />} />

          <Route path='/*' element={<FourZeroFour />} />
          {/* *************** Private Routes *************** */}
          {/* <Route
            path="/test"
            element={<PrivateRoute component={TestPage} layout={AuthLayout} />}
          /> */}
<Route
            path="/seller-profile"
            element={<PrivateRoute component={SellerProf} layout={DashboardLayout} navbar={true} />}
          />
          <Route
            path="/messenger"
            element={<PrivateRoute component={Messenger} layout={DashboardLayout} navbar={false} />}
          />

          <Route
            index
            path="/"
            element={<PrivateRoute component={Home} layout={DashboardLayout} navbar={true} />}
          />

          <Route
            path="/categories"
            element={<PrivateRoute component={Categories} layout={DashboardLayout} navbar={true} />}
          />

          <Route
            path="/addPost"
            element={<PrivateRoute component={StartListing} layout={DashboardLayout} navbar={true} />}
          />

          <Route
            path="/editProfile"
            element={<PrivateRoute component={EditProfileForm} layout={DashboardLayout} navbar={true} />}
          />

          <Route
            path="/profileDetails"
            element={<PrivateRoute component={SellerStoreProfile} layout={DashboardLayout} navbar={true} />}
          />

          <Route
            path="/buyerServices"
            element={<PrivateRoute component={BuyerServices} layout={DashboardLayout} navbar={true} />}
          />

          <Route
            path="/buyerAppointments"
            element={<PrivateRoute component={Appointments} layout={DashboardLayout} navbar={true} />}
          />
          <Route
            path="/BookAppointment"
            element={<PrivateRoute component={BookAppointment} layout={DashboardLayout} navbar={true} />}
          />

          <Route
            path="/startsetup"
            element={<PrivateRoute component={StartSetup} layout={DashboardLayout} navbar={true} />}
          />


          <Route
            path="/SellerAboutUs"
            element={<PrivateRoute component={SellerAboutUs} layout={DashboardLayout} navbar={false} />}
          />

          <Route
            path="/AboutUs"
            element={<PrivateRoute component={AboutUs} layout={DashboardLayout} navbar={false} />}
          />

          {/* <Route
            path="/buisnessinfoform"
            element={<PrivateRoute component={BuisnessInfoForm} layout={DashboardLayout} navbar={false} />}
          />

          <Route
            path="/HowPromoteworks"
            element={<PrivateRoute component={HowPromoteworks} layout={DashboardLayout} navbar={false} />}
          />

          <Route
            path="/ChooseYourPromotion"
            element={<PrivateRoute component={ChooseYourPromotion} layout={DashboardLayout} navbar={false} />}
          />


          <Route
            path="/ReviewOrder"
            element={<PrivateRoute component={ReviewOrder} layout={DashboardLayout} navbar={false} />}
          />

          <Route
            path="/PromotionInsights"
            element={<PrivateRoute component={PromotionInsights} layout={DashboardLayout} navbar={false} />}
          />

          <Route
            path="/myOrders"
            element={<PrivateRoute component={MyOrders} layout={DashboardLayout} navbar={false} />}
          /> */}
          <Route
            path="/review"
            element={<PrivateRoute component={HomeJourney} layout={DashboardLayout} navbar={false} />}
          />
          <Route
            path="/myOrders"
            element={<PrivateRoute component={MyOrders} layout={DashboardLayout} navbar={false} />}
          />
          <Route
            path="/tracking-number"
            element={<PrivateRoute component={TrackingNumber} layout={DefaultLayout} navbar={false} />}
          />
          <Route
            path="/customer-feedback"
            element={<PrivateRoute component={CustomerFeedback} layout={DefaultLayout} navbar={false} />}
          />

          <Route
            path="/dataprotection"
            element={<PrivateRoute component={DataProtection} layout={DashboardLayout} navbar={false} />}
          />

          <Route
            path="/findclient"
            element={<PrivateRoute component={FindClient} layout={DashboardLayout} navbar={false} />}
          />
          <Route
            index
            path="/Supporters"
            element={<PrivateRoute component={Supporters} layout={DashboardLayout} navbar={false} />}
          />

          <Route
            path="/workspace"
            element={<PrivateRoute component={WorkSpace} layout={DashboardLayout} navbar={false} />}
          />
          <Route
            path="/MoreInformation"
            element={<PrivateRoute component={MoreInformation} layout={DashboardLayout} navbar={false} />}
          />
          <Route
            path="/UserAccountSetting"
            element={<PrivateRoute component={UserAccountSetting} layout={DashboardLayout} navbar={false} />}
          />
          <Route
            path="/NotificationSetting"
            element={<PrivateRoute component={NotificationSetting} layout={DashboardLayout} navbar={false} />}
          />
          <Route
            path="/EmailNotification"
            element={<PrivateRoute component={EmailNotification} layout={DashboardLayout} navbar={false} />}
          />
          <Route
            path="/PrivacySetting"
            element={<PrivateRoute component={PrivacySetting} layout={DashboardLayout} navbar={false} />}
          />
          <Route
            path="/Security"
            element={<PrivateRoute component={Security} layout={DashboardLayout} navbar={false} />}
          />
          <Route
            path="/PaymentOption"
            element={<PrivateRoute component={PaymentOption} layout={DashboardLayout} navbar={false} />}
          />
          <Route
            path="/Balance"
            element={<PrivateRoute component={Balance} layout={DashboardLayout} navbar={false} />}
          />
          <Route
            path="/Postage"
            element={<PrivateRoute component={Postage} layout={DashboardLayout} navbar={false} />}
          />
          <Route
            path="/OrderReceipts"
            element={<PrivateRoute component={OrderReceipts} layout={DashboardLayout} navbar={false} />}
          />
          <Route
            path="/Contacts"
            element={<PrivateRoute component={Contacts} layout={DashboardLayout} navbar={false} />}
          />

          <Route
            path="/enteraddress"
            element={<PrivateRoute component={EnterAddress} layout={DashboardLayout} navbar={false} />}
          />
          <Route
            index
            path="/BuisnessProfile"
            element={<PrivateRoute component={BuisnessProfile} layout={DashboardLayout} navbar={false} />}
          />

          <Route
            path="/ConfirmYourAddress"
            element={<PrivateRoute component={ConfirmYourAddress} layout={DashboardLayout} navbar={false} />}
          />

          <Route
            path="/PinLocation"
            element={<PrivateRoute component={PinLocation} layout={DashboardLayout} navbar={false} />}
          />

          <Route
            path="/TravelFee"
            element={<PrivateRoute component={TravelFee} layout={DashboardLayout} navbar={false} />}
          />

          {/* <Route
            index
            path="/FeePolicy"
            element={<PrivateRoute component={FeePolicy} layout={DashboardLayout} navbar={false} />}
          /> */}

          <Route
            path="/TellMore"
            element={<PrivateRoute component={TellMore} layout={DashboardLayout} navbar={false} />}
          />

          <Route
            path="/BusinessHours"
            element={<PrivateRoute component={BusinessHours} layout={DashboardLayout} navbar={false} />}
          />

          <Route
            path="/AddServices"
            element={<PrivateRoute component={AddServices} layout={DashboardLayout} navbar={false} />}
          />

          <Route
            path="/SavedServices"
            element={<PrivateRoute component={SavedServices} layout={DashboardLayout} navbar={false} />}
          />

          <Route
            path="/AddPhoto"
            element={<PrivateRoute component={AddPhoto} layout={DashboardLayout} navbar={false} />}
          />

          {/* <Route
            path="/Congratulation"
            element={<PrivateRoute component={Congratulation} layout={DashboardLayout} navbar={false} />}
          /> */}

          <Route
            path="/order-detail"
            element={<PrivateRoute component={OrderDetail} layout={DashboardLayout} navbar={false} />}
          />
          <Route
            path="/invoice"
            element={<PrivateRoute component={Invoice} layout={DashboardLayout} navbar={false} />}
          />
          <Route
            path="/cancle-order"
            element={<PrivateRoute component={CancleOrder} layout={DefaultLayout} navbar={false} />}
          />
          <Route
            path="/report-buyer"
            element={<PrivateRoute component={ReportBuyers} layout={DefaultLayout} navbar={false} />}
          />
          <Route
            path="/payment-detail"
            element={<PrivateRoute component={PaymentDetail} layout={DefaultLayout} navbar={false} />}
          />
          <Route
            path="/refundbuyer"
            element={<PrivateRoute component={RefundBuyers} layout={DefaultLayout} navbar={false} />}
          />
          <Route
            path="/CheckOutStepTwo"
            element={<PrivateRoute component={CheckOutStepTwo} layout={DefaultLayout} navbar={false} />}
          />
           <Route
            path="/CheckOutStepThree"
            element={<PrivateRoute component={CheckOutStepThree} layout={DefaultLayout} navbar={false} />}
          />
            <Route
            path="/BusinessProfile"
            element={<PrivateRoute component={BusinessProfile} layout={DashboardLayout} navbar={true} />}
          />

<Route
            path="/BusinessUserProfile"
            element={<PrivateRoute component={BusinessUserProfile} layout={DashboardLayout} navbar={true} />}
          />



          <Route path="/*" element={<Frontend />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default RoutesComponent;
