import React from 'react'
import Reviews from '.';
import Profile1 from '../../../../accests/images/profile1.png'
import Profile2 from '../../../../accests/images/profile-2.png'
import Profile3 from '../../../../accests/images/profile-3.png'
import bag1 from '../../../../accests/images/bag.png'
import bag2 from '../../../../accests/images/bag-2.png'
import bag3 from '../../../../accests/images/bag-3.png'
import Leftarrow from '../../../../accests/images/ArrowLeft.svg'
import { useNavigate } from 'react-router-dom';
const content = [
  {
    image:Profile1,
      find:"Emma T.",
    heading: "",
    description:"Bought: A bag",
    cardStyle:bag1,
  },
  {
    image:
    Profile2,
      find:"Emma T.",
    heading: "",
    description:"Bought: A bag",
    cardStyle:bag2,
  },
   {

    image:Profile3,
      find:"Emma T.",
    heading: "",
    description:"Bought: A bag",
    cardStyle:bag3
  },
  
];

export default function HomeJourney() {
    const navigate=useNavigate();
  return (
    
    <>
   
    <div className='w-full py-3 px-5 sm:shadow-none   shadow-lg flex sm:justify-between items-center '>
        <img src={Leftarrow} alt=""className='w-[24px] h-[24px] cursor-pointer' onClick={()=>navigate(-1)}/>
     <h2 className='sm:text-[32px] text-base text-[rgb(199,46,53)] font-medium mx-5 sm:my-[41px]'>Reviews</h2>
     <div></div>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap" }} className='mt-4'>
        {content.map((item, index) => (
          <>
            <Reviews item={item} description={true}/>
            {/* {index === 1 &&
  <div style={{border:'2px solid #ee6c4d'}} className='cardStyle p-5 text-center hover-orange bor-radius'>
              <h3>Ready to start your search?</h3>
              <p className='mt-3'>Find homes with the features you care about most.</p>
              <button className='bg-blue border-none p-3 mt-3 bor-radius12 btn-hover'><span className='text-light fs-14 fw-bold'>View the latest listings</span></button>
              </div>} */}
          </>
        ))}

      </div>


    </>
  );
}