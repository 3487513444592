import React from 'react'
// import {IoIosArrowBack} from "react-icons/io"
import { Link } from 'react-router-dom'
import backArrow from "../../../../accests/iconsSvg/arrow.svg"
 
export default function AuthNavbar() {


    const handleGoBack = () => {
      window.history.back(); 
    };

  return (
    <div className='bg-[#F2F2F2]  py-[15px] md:py-[20px] md:px-[90px] px-[10px] lg:px-[146px]'>
      <Link>
        {/* <IoIosArrowBack  size={30} className='cursor-pointer' onClick={handleGoBack} /> */}
        <img src={backArrow} alt="" className='cursor-pointer md:w-[40px]  w-[30px] '  onClick={handleGoBack} /> 
      </Link>
   
    </div>
  )
}
