import React, { useState } from "react";
// import cross from "../../../accests/icons/cross.png";
import cross from "../../../accests/icons/cross.png";

// import complete from "../../../accests/icons/complete.png";
import complete from "../../../accests/iconsSvg/stepper red.svg";

// import arrowDown from "../../../accests/icons/arrowDown.png";
import arrowDown from "../../../accests/icons/arrowDown.png";

// import redarrowUp from "../../../accests/icons/redarrowUp.png";
import redarrowUp from "../../../accests/icons/redarrowUp.png";

// import leftArrow from "../../../accests/icons/leftArrow.png";
import leftArrow from "../../../accests/iconsSvg/Arrow - Left.svg";

// import Rectangle from "../../../accests/icons/Rectangle.png";
import Rectangle from "../../../accests/icons/Rectangle.png";

import "./CheckoutCompletedStyle.css";
import {  useSelector } from "react-redux";


export default function CheckoutCompleted() {
  const [isOpen, setIsOpen] = useState(false);

 
  const userDetail = useSelector((state) => state?.CheckOutSlice?.orderAddressDetail)
  const orderDetail = useSelector((state) => state?.CheckOutSlice?.totalCards)
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="mainBody">
        <img className="crossDiv" src={cross} alt="cross" />
        <img className="backArrow" src={leftArrow} alt="back" />
      </div>
      <div className="flex justify-center">
        <div className="mainContainer">
          <h1 className="checkOutHeading">Checkout success</h1>
          <img className="tick-image" src={complete} alt="Complete" />
          <div>
            <h1 className="thanks">Thanks for Shopping.</h1>
            <p className="imform-to-mail">
              When your order is done <br />
              processing, we’ll email the <br /> information to
              {' '}{userDetail?.email}.
            </p>
            <p className="order-price">Order total: ${userDetail?.tottal}.0</p>
            <div className="info-dropDown">
              {!isOpen && (
                <div onClick={toggleCollapse} className="flex">
                  <span className="order-info">See order info</span>
                  <img src={arrowDown} alt="Drop-Down" width={"24px"} />
                </div>
              )}
            </div>

            <div className={` ${isOpen ? "sm:shadow-md" : ""} `}>
              <div
                className={`order-details-div   ${isOpen ? "max-h-[1500px]" : "max-h-0"
                  } `}
              >
                <div className="postage-adress">
                  <p className="mb-1">Postage to:</p>

                  <p className="leading-5">
                    {/* 1 Barn Drive, Halesowen, West Midlands B62 9DH, United
                    Kingdom. */}
                    {userDetail?.addressOne},{userDetail?.addressTwo},{userDetail?.city},{userDetail?.state},{userDetail?.country}
                  </p>
                  <p className="order-id">Order no: 22-10556-36169</p>
                  {
                    orderDetail?.map((item) => (
                      <div className="product-section">
                        <div>
                          <img
                            src={Rectangle}
                            className="product-image"
                            alt="product"
                          />
                        </div>
                        <div className="product-name">
                          <p>
                            DOLCE & GABBANA THE ONE FOR MEN 1.5ml EDT SAMPLE SPRAY
                          </p>
                        </div>
                      </div>
                    ))
                  }


                  <p>
                    <u className="see-details"> See order details</u>
                  </p>
                </div>
              </div>
            </div>

            {!isOpen || (
              <div onClick={toggleCollapse} className="hide-collapse">
                <span className="hide-btn">Hide order details</span>
                <img
                  src={redarrowUp}
                  alt="Drop-Down"
                  className="rotate-180"
                  width={"24px"}
                />
              </div>
            )}

            <center>
              <button className="continue-btn">Continue</button>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
}
