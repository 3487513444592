import React, { useEffect, useState } from 'react';
// import { MdDelete } from "react-icons/md";
// import PlusIcon from '../Images/Plusicon.png'
// import MinusIcon from '../Images/Minus.png'

import CheckOutFirstCard from '../CheckOutFirstCard/CheckOutFirstCard';
import { useSelector } from 'react-redux';

const CheckOutStepOne = ({ setNav }) => {
    const cardExampleData = [{ title: 'Gym Shirt', condition: 'New', size: 'L', price: 25 }, { title: 'Gym Shirt', condition: 'New', size: 'L', price: 25 }]
    let [allTottalPrice, setAllTottalPrice] = useState(0)

    const totalCards = useSelector((store)=>store.CheckOutSlice?.totalCards) 

    const setTottal = (cat, e) => {
        if (cat === 1) {
            let tottal = allTottalPrice + e
            setAllTottalPrice(tottal)

        }

    }

    const calculateTotalPrice = () => {
        if(allTottalPrice === 0 ){
            const totalPrice = cardExampleData.reduce((accumulator, data) => {
                return accumulator + data.price;
            }, 0);
    
            setAllTottalPrice(totalPrice);
        }
    

    };

    // Call the function once, possibly inside a useEffect or other lifecycle method
    useEffect(() => {
        calculateTotalPrice();
    }, []);
    return (
        <>
            <div className='w-full mt-[14px] h-full flex justify-center items-end '>
                <div className='w-[95%] flex-col justify-center md:w-[610px] '>
                    <hr className='border-[#E1E1E1]' />
                    {
                        totalCards?.map((data, i) => {
                            return (
                                // <div className=' w-full h-[206] md:w-[603px] md:h-[273px] mt-[12px] bg-white py-[12px] px-[8px] rounded-xl flex justify-center shadow-[0px_0px_6px_0px_#00000024]'>
                                //     <div>
                                //         <img src={MyImg} alt="" className='w-[100px] h-[160px]  md:w-[156px] md:h-[249px]' />
                                //     </div>
                                //     <div className=' ml-[17px]'>
                                //         <div className=' h-[70px] w-[90px] md:w-[118px] '>
                                //             <div className='text-text-base font-bold block md:text-xl'>{data.title}</div>
                                //             <div className='text-xs md:text-base'>{data.condition}</div>
                                //             <div className='text-xs md:text-base'>Size: {data.size}</div>
                                //         </div>
                                //         <div className=' mt-10 h-[48px] w-[90px]  flex items-center md:mt-[98%] '>

                                //             <img onClick={incriment} src={PlusIcon} alt="" />
                                //             <div className='text-[18px] font-semibold md:text-[20px]'>2</div>
                                //             <img onClick={incriment} src={MinusIcon} alt="" />
                                //         </div>
                                //     </div>

                                //     <div className='w-[45.5%] flex justify-end'>
                                //         <div className=' flex-col '>
                                //             <div className='h-12 w-12 rounded-lg border flex justify-center items-center '>

                                //                 <img src={Dell} className='w-[24px] h-[24px]' alt="" />
                                //             </div>
                                //             <div className='mt-[62px] h-[48px] flex items-center text-[18px] md:text-[22px] font-semibold md:mt-[136px]'> $ {data.price}.0</div>

                                //         </div>
                                //     </div>
                                // </div>
                                <CheckOutFirstCard setTottal={setTottal} data={data} />
                            )
                        })
                    }


                    <div className='w-full md:mt-[35px] mt-[350px]'>
                        <div className='h-[74px] w-full sm:h-[88px] sm:w-[603px] text-[14px] md:text-[20px] m-auto pl-[15px] pr-[15px]'>
                            <table className='w-full' >
                                <tr className='flex justify-between items-center h-[37px] font-normal border-t border-[#EFEFEF]'>
                                    <td>Item ({totalCards?.length})</td>
                                    <td>USD: {allTottalPrice}.0</td>
                                </tr>
                                <tr className='flex justify-between items-center h-[37px] font-bold border-t border-[#EFEFEF]'>
                                    <td>Item ({totalCards?.length})</td>
                                    <td>USD: {allTottalPrice}.0</td>
                                </tr>
                            </table>
                        </div>

                    </div>
                    <div className='flex justify-center w-full md:mt-[27px] '>
                        <div onClick={()=>setNav(0)} className='bg-[#BF1017] cursor-pointer rounded-lg w-[95%] flex justify-center items-center h-[48px] pl-[15px] pr-[15px]  sm:w-[603px] '>
                            <div className='font-semibold text-lg text-[#FFFFFF]'> Proceed to checkout</div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default CheckOutStepOne;
