

const Reviews = ({ item, description, cardStyle }) => {
    return (
      <>
     
      <div className='w-[1103px] mx-auto  p-3'>

    <div className='shadow-lg rounded-lg flex justify-between w-[100%] p-2  sm:p-5 mb-[26px]'>
      <div > <img src={item.image} alt width={"100%"} style={{borderRadius:"10px"}} className="zoom sm:w-[104px] sm:h-[109px]  w-[40px] h-[37px]"/></div>

      <div className='sm:w-[80%]  w-[48%] sm:px-3'>
        <h1 className='text-sm sm:text-[28px] not-italic mb-2 font-bold'>{item.find}</h1>
        <div className='text-[rgb(199,46,53)]  mb-2 w-[137px] flex text-sm sm:w-full items-center'>&#9733;  &#9733;  &#9733;  &#9733;  &#9733;{item.heading} <p className='text-[#777] text-[10px] sm:text-sm not-italic font-medium leading-4 ml-1'>26/01/2023</p>
        </div>

        {description &&  <h1 className='sm:text-2xl mb-2 text-[12px] text-[rgb(0,0,0,0.70)] font-semibold '>{item.description}</h1>}
        <h1 className='sm:text-base   text-[10px] text-[rgb(0,0,0,0.70)] font-normal '>Perfect bag as advertised. It is beautiful! Thank you!</h1>
        </div>

        <div><img src={item.cardStyle} className=' sm:w-[261px] sm:h-[167px]  w-[147px] h-[106px]'  alt="" /></div>
    </div>

    </div>
     
      </>
    );
  };
  
  export default Reviews;