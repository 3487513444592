import React, { useState, useRef } from 'react';
// import bxs_user from "../../icons/bxs_user.svg";
// import leftArrow from "../../icons/leftArrow.png";
import * as Yup from 'yup';
import { APICallMethod} from 'services/api/auth';
import { ENDPOINTS, REQUEST_TYPES } from 'utils/constants/url';
import { toast } from 'react-toastify';
import leftArrow from "../../../accests/iconsSvg/Arrow - Left.svg";
import bxs_user from "../../../accests/icons/bxs_user.svg"
import "../../../style/EditProfileStyle.css"
 
const EditProfileForm = () => { 


  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);

  const handleImageOnChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg')) {
      setImagePreview(URL.createObjectURL(file));
      setState(prevData => ({ ...prevData, profileImage: file }));
    } else {
      setImagePreview(null);
      // Inform the user that only PNG, JPG, and JPEG images are allowed
      toast.error("Only PNG, JPG, and JPEG images are allowed.");
    }
  };

  const handleImageUploadClick = () => {
    fileInputRef.current.click();
  }

  const [state, setState] = useState({
    profileImage: "",
    name: "",
    userName: "",
    bio: "",
    status: "",
  });


  const [errors, setErrors] = useState({});

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[a-zA-Z ]+$/, {
        message: "Please fill a valid name (only alphabets are allowed)",
        excludeEmptyString: true,
      }),
      
      
    userName: Yup
      .string()
      .required("User Name is required")
      .matches(/^(?!0\d)\d*[a-zA-Z]*\d*$/, {
        message: "Please fill a valid username (only natural numbers and alphabets are allowed)",
        excludeEmptyString: true,
      })
      ,
    
  });


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let formData = new FormData();
      formData.append("name", state?.name);
      formData.append("userName", state?.userName);
      formData.append("bio", state?.bio);
      formData.append("status", state?.status);
      formData.append("profileImage", state?.profileImage);

      const isValid = await validationSchema.validate(state, { abortEarly: false });
      
      if (isValid) {
        const { isError, message } = await APICallMethod(
          REQUEST_TYPES.POST,
          `${ENDPOINTS.EDIT_PROFILE}`,
          formData
        );

        if (isError) {
          toast.error(message);
          return
        }
        toast.success(message);

      }

    } catch (validationErrors) {
     
      if (validationErrors instanceof Yup.ValidationError && validationErrors.inner) {
        const newErrors = {};
        validationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setErrors(newErrors);
      } else {
        
      }
    }

  }

  const handleOnChange = (e) => {

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));

    const { name, value } = e.target;
    setState(prevData => ({ ...prevData, [name]: value }));
  };

  return (
    <div className='md:flex block'>
      <div className='small-screen-header'>
        <img src={leftArrow} alt=""/>
        <h2 className='edit-profile-heading'> Edit Profile</h2>
        <div></div>
      </div>
      <div className='large-screen-back-button'>
        <img className="back-btn" src={leftArrow} alt="Back" />
      </div>
      <div className="edit-profile-main">
        <div className="mt-12 mb-14">

          <div className="flex justify-center">
            <div>

            </div>
            <div className="container mx-auto p-4">
              <div className="flex flex-col items-center">
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleImageOnChange}
                  className="hidden"
                  accept="image/*"
                />
                <div
                  onClick={handleImageUploadClick}
                  className="cursor-pointer bg-[#BF1017]  mb-[12px]  rounded-xl text-white"
                >
                  <img
                    src={imagePreview ? imagePreview : bxs_user}
                    alt="Profile Preview"
                    className={` ${imagePreview ? "w-[84px] h-[84px]" : "w-[48px] h-[48px] m-[18px]"} rounded-lg  max-w-xs`}
                  />

                </div>
                <p className="change-profile-text">Change Profile Photo</p>
              </div>
            </div>
            <div></div>
          </div>
        </div>

        <form action="#" onSubmit={handleSubmit}>
          <div className="input-box">
            <label
              for="message"
              className="input-label"  
            >
              Name:
            </label>
            <input
              type="text"
              name="name"
              onChange={handleOnChange}
              placeholder='Write Here'
              className={`form-input ${errors.name ? "border-red-500" : "border-[#777]"}`}
            />
                {errors.name && <p className="error-text text-red-500 text-[12px]">{errors.name}</p>}
          </div>

          <div className="input-box">
            <label
              for="message"
              className="input-label"
            >
              User Name:
            </label>
            <input
              type="text"
              onChange={handleOnChange}
              name="userName"
              placeholder='Write Here'
              className={`form-input ${errors.name ? "border-red-500" : "border-[#777]"}`}
            />
              {errors.userName && <p className="error-text text-red-500 text-[12px]">{errors.userName}</p>}
          </div>



          <div className="input-box">
            <label
              for="message"
              className="input-label"
            >
              Bio:
            </label>
            <input
              type="text"
              name="bio"
              onChange={handleOnChange}
              placeholder='Write Here'
              className="form-input"
            />
          </div>


          <div className='category-selection-box'>
            <h5 className="heading-for-radio">
              User name:
            </h5>

            <div className="radio-btn-box">
              <div className="radio-btn" >
                <input
                  id="country-option-2"
                  type="radio"
                  name="status"
                  onChange={handleOnChange}
                  value="Public"
                  className="selection-radio"
                  defaultChecked
                />
                <label
                  for="Private"
                  className="selection-label"
                >
                  Public
                </label>
              </div>

              <div className="radio-btn sm:w-1/4 w-1/2">
                <input
                  id="country-option-2"
                  type="radio"
                  name="status"
                  onChange={handleOnChange}
                  value="private"
                  className="selection-radio"

                />
                <label
                  for="Private"
                  className="selection-label"
                >
                  Private
                </label>
              </div>
            </div>
          </div>
          <button className="profile-update-btn">
            Update
          </button>
        </form>
      </div>

    </div>
  )
}

export default EditProfileForm;