import React, { useEffect, useState } from "react";
import grid from "../../../accests/iconsSvg/my Store.svg";
// import grid from "../../../accests/images/grid.png";
// import bxuserPin from "../../../accests/images/bxuserPin.png";
import bxuserPin from "../../../accests/iconsSvg/My appointement.svg";
import { Button } from '@material-tailwind/react';

import item1 from "../../../accests/images/Green Jumr.png";
import item2 from "../../../accests/images/T-shirt1.png";
import item3 from "../../../accests/images/poloshitrs.png";
import item4 from "../../../accests/images/Dress1.png";
import star from "../../../accests/images/star.png";
// import Dropdown from "./ToggleDropdown";
import MyPromo from '../../../accests/iconsSvg/MyPromo.svg'
import MyPromoRed from '../../../accests/iconsSvg/MyPromoRed.svg'
import MyStoreRed from '../../../accests/iconsSvg/My store icon.svg'
import MyStore from '../../../accests/iconsSvg/MyStore.svg'
import MySaved from '../../../accests/iconsSvg/MySaved.svg'
import MySavedRed from '../../../accests/iconsSvg/MySavedRed.svg'

import SellerDashbordProfile from "../Components/SellerDashbordProfile/SellerDashbordProfile";
import SellerDashbordSlection from "../Components/SellerDashbordSlection/SellerDashbordSlection";
import SellerDashbordProductSlc from "../Components/SellerDashbordProductSlc/SellerDashbordProductSlc";
import SellerDashbordProductCrd from "../Components/SellerDashbordProductCrd/SellerDashbordProductCrd";
import SellerDashbordAppointment from "../Components/SellerDashbordAppointment/SellerDashbordAppointment";
import EditProfileForm from "../EditProfileForm/EditProfileForm";


export default function SellerStoreProfile() {

    const [form, setForm] = useState(false)
    const [isActive,setisActive]=useState(1);

    const [active, setActive] = useState(false) 
    const itemCard = [
        { 
            image: item1,
            title: "Green jumper",
            rating: "4.0",
            star: star,
            price: "$50.00",
        },
        {
            image: item2,
            title: "T-Shirt",
            rating: "4.0",
            star: star,
            price: "$50.00",
        },

        {
            image: item3,
            title: "Polo Shirts",
            rating: "4.0",
            star: star,
            price: "$50.00",
        },
        {
            image: item4,
            title: "Dress",
            rating: "4.0",
            star: star,
            price: "$50.00",
        },
        {
            image: item1,
            title: "Wite T-shirt",
            rating: "3.0",
            star: star,
            price: "$50.00",
        },
        {
            image: item2,
            title: "Wite T-shirt",
            rating: "2.0",
            star: star,
            price: "$50.00",
        },

        {
            image: item3,
            title: "Wite T-shirt",
            rating: "5.0",
            star: star,
            price: "$50.00",
        },
        {
            image: item4,
            title: "Wite T-shirt",
            rating: "4.0",
            star: star,
            price: "$50.00",
        },
    ];

    let user = {
        name: "Paul David",
        order: 10,
        post: 0,
        sporting: 70,
        sports: 30,
        rating: 3,
        tottalReview: 3,
        discription: ' ‘’I am happy to sell, I care about my customers and you get the best products!!!'
    }


    useEffect(() => {
        // let response = axios.get('/example url')

        // if(Response.staus === 200){
        //     console.log('response', Response)
        // }else{
        //     console.log('response', Response)
        // }


    }, [])


    const ediFormNav = () => {
        setForm(true)
    }



    return (
        <div className="body">
            {
                form ? 
                <EditProfileForm/>
                :
                <div>
                <div className="padding-lr">

                    <SellerDashbordProfile ediFormNav={ediFormNav} user={user} />
 
           
                    <SellerDashbordSlection active={active} />





                 

                </div>




                {/* <div className="sm-filter-icons">
                <div>
                    <img className="dashboard-icon" src={dashboard} alt="" />
                    <p className="dashboard-icon-heading">My Store</p>
                </div>
                <div>
                    <img className="dashboard-icon m-1" src={video} alt="" />
                    <p className="dashboard-icon-heading">My ProMos</p>
                </div>
                <div>
                    <img className="dashboard-icon m-1" src={save} alt="" />
                    <p className="dashboard-icon-heading">My Saved</p>
                </div>
            </div> */}

                <div className="px-2">
                    <hr className="md:hidden" />
                </div>


                {
                    !active ?
                        <div className="products-section">
                            {itemCard.map((itemCard, index) => (

                                <SellerDashbordProductCrd key={index} itemCard={itemCard} />
                            ))}
                        </div>
                        :
                        <SellerDashbordAppointment />

                }



            </div>
            }
           
        </div>
    );
}
