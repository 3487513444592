import React from "react";

function DefaultLayout({ children }) {
    return (
        <>
            {children}
        </>
    );
}

export default DefaultLayout;
