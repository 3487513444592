import React from 'react';
import profile2 from '../../../../accests/images/Sseller.png'

import menu from '../../../../accests/images/Vector.png'
import Demo from '../../../../accests/images/messengerDem.jpeg'

const MessengerChatBoxTwo = () => {
    return (
        <div className='shadow-md rounded-[8px] p-[12px]'>
            <div className='flex  px-2 py-3 mb-3 rounded-md  justify-between'>
                <div className='flex items-center'>
                    <img width={48} src={profile2} alt="" /> 

                    <div className='ml-2'>
                        <p className='font-bold text-[16px] '>Chirs Howl</p>
                        <p className=' text-[12px] text-gray-400 '>2 hours ago</p>
                    </div>
                </div>
                <div className=' mt-2 mr-2 flex items-center'>
                  
                    <div className='ml-5'><img  src={menu} alt="" /></div>

                </div>
            </div>
               <div>
                <img src={Demo} alt="" />
               </div>
        </div>
    );
}

export default MessengerChatBoxTwo;
