import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import done from "../../../../accests/iconsSvg/ReportedVector.svg";
// import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

export default function ReportedModal({ isOpen, handleModal }) {
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  useEffect(() => {
    setOpen(isOpen);
    // handleModal()
  }, [isOpen]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                       <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div> */}
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 flex flex-col items-center"
                      >
                        <h3 className="sm:font-[700] sm:text-[22px] mb-[16px]">
                          The post has been reported
                        </h3>
                        <div className="sm:py-[10px]">
                          <img src={done} alt="" />
                        </div>
                      </Dialog.Title>
                      <div className="mt-2 sm:p-[10px]">
                        <p className="text-left text-[14px] sm:text-[18px] sm:font-[600] font-[400] leading-normal">
                          You have reported this post with reason “Scam” and and
                          doesn’t follow Sellout community guideline, Team
                          Sellout will look into your report and take action in
                          24 hours!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" px-4 py-3 mb-[16px] sm:px-6 flex flex-col-reverse sm:flex-row items-center sm:justify-center">
                  <button
                    type="button"
                    className="rounded-md font-semibold text-[#BF1017] p-2 shadow-sm border w-10/12 sm:w-[150px] sm:mr-3 mt-4 sm:mt-0"
                    onClick={() => handleModal(false)}
                  >
                    Cancel this report
                  </button>
                  <button
                    type="button"
                    className="rounded-md bg-[#BF1017] text-white p-2 font-semibold  w-10/12 sm:w-[150px] sm:ml-3"
                    onClick={() => handleModal(false)}
                    ref={cancelButtonRef}
                  >
                    Done
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
