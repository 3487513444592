import { createSlice } from "@reduxjs/toolkit";


const CheckOutSlices = createSlice({
    name: "CheckOutSlice",
    initialState: {
        functions: [],
        totalCards: [{ id: 1, title: 'Gym Shirt', condition: 'New', size: 'L', price: 25, totalPrice: 25 }, { id: 2, title: 'Gym Shirt', condition: 'New', size: 'L', price: 25, totalPrice: 25 }],
        orderAddressDetail: {}
    },
    reducers: {
        checkOutfirstFunction(state, action) {

            let index = state.totalCards.findIndex((item) => item.id === action.payload.id)
            if (index !== -1) {
                state.totalCards[index].totalPrice += action.payload.price
            }
           
        },

        orderAddress(state, action) {
            state.orderAddressDetail = action.payload

        },

        addTottal(state , action){
        state.orderAddressDetail = {...state.orderAddressDetail , tottal:action.payload}


        }


    },

})

export default CheckOutSlices;

export const {
    checkOutfirstFunction,
    orderAddress,
    addTottal

} = CheckOutSlices.actions;