import React from 'react';
import Topbar from 'pages/Frontend/Components/Topbar/Topbar';

function DashboardHeader() {
    return (
        <>
            <Topbar />
        </>
    )
}

export default DashboardHeader;