import React, { useState } from 'react'
// import CheckoutForm from '../Form'
import CheckOutForm from '../CheckOutForm/CheckOutForm';

// import MainCard from '../MainCard';
import CheckOutMainCard from '../CheckOutMainCard/CheckOutMainCard'; 
import { useDispatch, useSelector } from 'react-redux';
import { orderAddress } from 'store/FunctionSlice/CheckOutSlice';
import GotoNext from '../../../../accests/images/arrow.png'
const CheckOutStepTwo = ({ setNav }) => {
    const [formData, setFormData] = useState({ email:'example@gmail.com', addressOne: '', addressTwo: '', state: '', country: '', city: '', zipCode: '' })
    const [valid, setValid] = useState({ addressOne: 0, addressTwo: 0, state: 0, country: 0, city: 0, zipCode: 0 })
    const dispatch = useDispatch() 
    const totalCards = useSelector((store) => store.CheckOutSlice?.totalCards)
    const getForm = (e) => {
        if (e.target.name === 'addressLineOne') {
            setFormData({ ...formData, addressOne: e.target.value })

        }
        if (e.target.name === 'addressLineTwo') {
            setFormData({ ...formData, addressTwo: e.target.value })
        }
        if (e.target.name === 'state') {
            setFormData({ ...formData, state: e.target.value })
        }
        if (e.target.name === 'city') {
            setFormData({ ...formData, city: e.target.value })
        }
        if (e.target.name === 'country') {
            setFormData({ ...formData, country: e.target.value })
        }
        if (e.target.name === 'zipCode') {
            setFormData({ ...formData, zipCode: e.target.value })
        }
    }

    const submit = () => {
        if(formData.title !== '' && formData.addressOne !== '' && formData.addressTwo !== '' && formData.city !== '' && formData.state !== '' && formData.country !== ''){
            dispatch(orderAddress(formData))
            setNav(2)
        }else if(formData.title === '' || formData.addressOne === '' || formData.addressTwo === '' || formData.city === '' || formData.state === '' || formData.country === ''){
        if(formData.addressOne === ''){
            setValid({...valid , addressOne: 1})
        }  else if(formData.addressTwo === ''){
            setValid({...valid , addressTwo: 1})
        }
       else  if(formData.city === ''){
            setValid({...valid , city: 1})
        }
      else   if(formData.state === ''){
            setValid({...valid , state: 1})
        }
       else  if(formData.country === ''){
            setValid({...valid , country: 1})
        }
        else  if(formData.zipCode === ''){
            setValid({...valid , zipCode: 1})
        }
      }
    }

    return (
        <div className='w-full rounded-xl pt-[12px] pb-[12px] pl-[8px] pr-[8px] flex justify-center'>
            <div className='w-full flex justify-center rounded-xl'>

                <div className='w-full sm:w-[603px]  rounded-xl shadow-[_0px_0px_6px_0px_#00000024] justify-center pt-[14px] p-2 '>
                    {
                        totalCards?.map((data, i) => {
                            return (
                                <CheckOutMainCard data={data} />
                            )
                        })
                    }
                    {/* <CheckOutMainCard /> */}
                    <div className='pt-[14px] flex justify-center'>
                        <div className=' flex justify-between w-full text-sm pt-[8px] pb-[8px] pl-[8px] pr-[8px] font-medium shadow-[0px_2px_6px_0px_#00000014] rounded-xl md:w-[587px]'>
                            <div className='w-[297px] text-[18px] md:w-[514px] '>
                                <div>Postpage</div>
                                <div className='text-[#1A9D3D] leading-[21.78px] '>Free 2-4 day Post page</div>
                                <div className='pt-[8px] text-xs font-light pb-[8px] md:text-[14px] leading-[16.94px]'>
                                    Get it by 26-30 May <br /> Other 48Courier
                                </div>
                            </div>
                            <div className='flex items-center justify-center '><img src={GotoNext} alt="" /> </div>


                        </div>
                    </div>
                    <div className='pt-[14px] flex justify-center'>
                        <div className='flex justify-between w-full text-sm pt-[8px] pb-[8px] pl-[8px] pr-[8px] font-medium shadow-[0px_2px_6px_0px_#00000014] rounded-xl md:w-[587px]'>
                            <div className='w-[297px] md:w-[514px] text-[18px] '>
                                <div>Post to:</div>
                                {/* <div className='text-[#1A9D3D]'>Free 2-4 day Post page</div> */}
                                <div className='pt-[12px] pb-[8px] text-xs font-light md:text-[14px] leading-[16.94px] '>
                                    Model Town, Lahore Cantt., Punjab - Central, Model Town, Lahore Cantt., Punjab, Central, Model Town, Lahore Cantt., Punjab - Central
                                </div>
                            </div>
                            <div className='flex items-center justify-center'><img src={GotoNext} alt="" /> </div>

                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <CheckOutForm getForm={getForm} valid={valid} />
                    </div>

                    <div onClick={submit } className='w-full cursor-pointer mt-[12px] h-12 md:w-full md:h-[48px] text-white font-semibold rounded-lg flex justify-center items-center bg-red-700 md:mt-12'>Proceed to payment</div>
                </div>
            </div>
        </div>
    );
}

export default CheckOutStepTwo;
