import React, { useState } from 'react';
import CheckOutStepper from '../Components/CheckOutStepper/CheckOutStepper';
import CheckOutStepOne from '../Components/CheckOutStepOne/CheckOutStepOne';
import CheckOutStepTwo from '../Components/CheckOutStepTwo/CheckOutStepTwo';
import CheckOutStepThree from '../Components/CheckOutStepThree/CheckOutStepThree';
import { useNavigate } from 'react-router';

const CheckOut = () => {
    const [active, setActive] = useState(0)

    const navigate = useNavigate();
    const setNav = (page) => {
        if (page === 2) {
            setActive(2)
        } else if (page === 0) {
            setActive(1)
        }


    }

    const placeOrder = () => {
        navigate('/checkoutcompleted')
    }

    return (
        <div>
            <CheckOutStepper active={active} />
            {
                active === 0 ?
                    <CheckOutStepOne setNav={setNav} />
                    : active === 1 ?
                        <CheckOutStepTwo setNav={setNav} />
                        : <CheckOutStepThree placeOrder={placeOrder} />
            }
        </div>
    );
}

export default CheckOut;
