import React from 'react';
import Search from '../../../../accests/images/search.png'
import friend from '../../../../accests/images/friend.png'
import profile1 from '../../../../accests/images/message_photo.png'

import chat from '../../../../accests/images/chat.png'

const MessageCard = ({switchButtonActive , user}) => {
    return (
        <div className='h-[80vh] mt-5 overflow-y-auto'>
                    <div className='mb-3'>
                        <div className='flex justify-between '>
                            <div className='flex w-[85%]  items-center shadow-md shadow-gray-300 py-3 px-3 rounded-md'>< img className='w-[15px] h-[15px]' src={Search} alt="" /><input className='outline-none ml-2 w-[90%] text-sm' type="text" placeholder='Search' /></div>
                            <div className=' flex items-center mr-3 justify-center'> <img className='W-[20PX] h-[20px]' src={switchButtonActive === 0 ? chat : friend} alt="" /></div>
                        </div>
                    </div>
                    <div className=''> 
                        {
                            user.map((data,i) => {
                                return (
                                    <div key={i} className='flex shadow-md p-2 mb-3 rounded-md shadow-gray-300 justify-between'>
                                        <div className='flex'>
                                            <img src={data.img} alt="" />

                                            <div className='ml-2'>
                                                <p className='font-bold '>{data.name}</p>
                                                <p className=' text-[14px] '>{data.meesage}</p>
                                            </div>
                                        </div>
                                        <div className=' mt-2 mr-2 '>
                                            <p className='text-[10px] text-gray-400 font-semibold'>14:47 pm</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className='flex shadow-md p-2 mb-3 rounded-md shadow-gray-300 justify-between'>
                            <div className='flex'>
                                <img className='rounded-full' src={profile1} alt="" />

                                <div className='ml-2'>
                                    <p className='font-bold '>Chirs Howl</p>
                                    <p className=' text-[14px] '>Yes, of course!</p>
                                </div>
                            </div>
                            <div className=' mt-2 mr-2 '>
                                <p className='text-[10px] text-gray-400 font-semibold'>14:47 pm</p>
                            </div>
                        </div>
                    </div>
                </div>
    );
}

export default MessageCard;
