import { getNetwrokInstractureAPI } from "services/api/charts/stats";
import { getCookie, setCookie } from "services/session/cookies";
import { REQUEST_TYPES } from "utils/constants/url";
import { formatDistanceToNow, differenceInSeconds } from 'date-fns';

export const logOut = () => {
  setCookie("accessToken", "");
  setCookie("refreshToken", "");
  setCookie("user", "");
};

export const allowedCountriesPhone = ['+92', '+1'];

export const getForgotByValue = (option, userInfo) => {
  let value = '';

  if (option === 'email') {
    value = userInfo?.email;
  } else if (option === 'phoneNumber') {
    value = userInfo?.phoneNumberDetails?.completePhoneNo;
  }
  // debugger
  return value;
}

export const setTextLimit = (value, limit = 50) => {
  if (value) {
    value = value?.slice(0, limit);
  }
  return value;
}

export const getTimeAgo = (date) => {
  const selectedDate = new Date(date);

  if (selectedDate !== 'NaN') {
    const now = new Date();
    const timeDifferenceInSeconds = differenceInSeconds(now, selectedDate);

    if (timeDifferenceInSeconds < 60) {
      return 'Just now';
    } else {
      return formatDistanceToNow(selectedDate, { addSuffix: true });
    }
  }
  return 'N/A';
}

export const dropDownValuesFormatter = (data = []) => {
  return data
    .map((val) =>
      val
        ? {
          label: val,
          value: val,
          link: "",
        }
        : null
    )
    .filter((val) => val !== null)
    .sort();
};
export const queryParamsCreator = (params) => {
  let queryString = "";
  if (params && Object.keys(params).length) {
    Object.keys(params).forEach((k) => {
      if (!params[k]) {
        delete params[k];
      }
    });
    if (Object.keys(params).length) {
      const searchParams = new URLSearchParams(params);
      queryString = searchParams.toString();
    }
  }
  if (queryString) {
    queryString = `?${queryString}`;
  }
  return queryString;
};
export const getCookiesByName = (name, isParse) => {
  let data = "";
  if (name) {
    data = getCookie(name) || "";
    if (data && isParse) {
      data = JSON.parse(data);
    }
  }
  return data;
};
export const setCookiesByName = (name, value, isStringify) => {
  if (name && value && isStringify) {
    return setCookie(name, JSON.stringify(value));
  }
  name && setCookie(name, value);
};
// export const UniqueValues = (data, key) => {
//   const Data = [];
//   data &&
//     data?.length &&
//     data?.map((obj) => {
//       const index = Data.findIndex((x) => x[key] === obj[key]);
//       if (index === -1) {
//         Data.push(obj);
//       }
//     });
//   return Data;
// };

export const fetchData = async (url, setState) => {
  try {
    // setLoading(true);
    const { data, isError } = await getNetwrokInstractureAPI(REQUEST_TYPES.GET, url);
    if (!isError && data) {
      setState(data);
    }
    // setLoading(false);
  } catch (error) {
    console.log(error.message);
  }
};
export const getDatesRangeValue = (data) => {
  const [startDate, endDate] = data || [];
  return { startDate, endDate };
};

export const dhm = (ms) => {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  // const daysms = ms % (24*60*60*1000);
  // const hours = Math.floor(daysms / (60*60*1000));
  // const hoursms = ms % (60*60*1000);
  // const minutes = Math.floor(hoursms / (60*1000));
  // const minutesms = ms % (60*1000);
  // const sec = Math.floor(minutesms / 1000);
  return days;
};
export const getColor = (value = "") => {
  switch (value) {
    case "Over Size":
      return "#5C9AD5";
    case "Right Size":
      return "#247818";
    case "Under Size":
      return "#DD0000";
    case "Not Available":
      return "#757575";

    default:
      return " rgb(221, 221, 221)";
  }
};
