import React from "react";
import Header from './Header';
import Navbar from './Navbar';

function DashboardLayout({ children, navbar = false }) {
    return (
        <>
            <Header />
            {navbar && <Navbar />}
            {children}
        </>
    );
}

export default DashboardLayout;
