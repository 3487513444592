import React from 'react';
import profile2 from '../../../../accests/images/Sseller.png'
import messengerMenu from '../../../../accests/images/messenger_menu.png'
const MessengerServices = ({messengerServiceCard}) => {
    return (
        <div className='shadow-md shadow-gray-300 mt-5 rounded-md bg-white h-[80vh]'>
            {
                messengerServiceCard.map(()=>{
                    return(
                        <div className='flex items-center shadow-sm rounded-sm p-2 shadow-gray-300 mb-3'>
                        <div className='flex w-full items-center py-2'>
                            <img src={profile2} alt="" />
                            <p className='ml-3 font-semibold'>My Stories</p>
                        </div>
                        
                        <img className='h-[20px] w-[4px]' src={messengerMenu} alt="" />
                    </div>
                    )
                })
            }
           
        </div>
    );
}

export default MessengerServices;
