import React from "react";
import card1 from "../../../../accests/images/tradingcard.png";
import { Link } from "react-router-dom";
export default function TrendingProducts() {
  return (
    <div className="my-10">
      <div className="text-[16px] font-[500] ">Trending products</div>
      <div className="flex gap-2">
        <div className=" bg-white shadow-md p-[8px] rounded-[8px]  mt-5 "> 
          <div className="">
            <img src={card1} alt="" /> &nbsp;
            <p>Soofa</p>
            <p className="flex items-center gap-1">
              London UK{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="12"
                viewBox="0 0 9 12"
                fill="none"
              >
                <path
                  d="M4.375 11.6261C3.58559 10.9527 2.85387 10.2145 2.1875 9.41922C1.1875 8.22485 5.49309e-07 6.44609 5.49309e-07 4.7511C-0.000433137 3.88545 0.255943 3.03912 0.736684 2.31924C1.21742 1.59935 1.90092 1.03826 2.70067 0.706969C3.50042 0.375681 4.38047 0.289089 5.22945 0.458152C6.07843 0.627216 6.85817 1.04433 7.47 1.65672C7.87735 2.06226 8.20022 2.54456 8.41993 3.0757C8.63964 3.60685 8.75183 4.1763 8.75 4.7511C8.75 6.44609 7.5625 8.22485 6.5625 9.41922C5.89613 10.2145 5.16441 10.9527 4.375 11.6261ZM4.375 2.8761C3.87772 2.8761 3.40081 3.07364 3.04918 3.42527C2.69754 3.7769 2.5 4.25381 2.5 4.7511C2.5 5.24838 2.69754 5.72529 3.04918 6.07692C3.40081 6.42855 3.87772 6.6261 4.375 6.6261C4.87228 6.6261 5.3492 6.42855 5.70083 6.07692C6.05246 5.72529 6.25 5.24838 6.25 4.7511C6.25 4.25381 6.05246 3.7769 5.70083 3.42527C5.3492 3.07364 4.87228 2.8761 4.375 2.8761Z"
                  fill="black"
                  fillOpacity="0.5"
                />
              </svg>
            </p>
          </div>
        </div>
        <div className=" bg-white shadow-md p-[8px] rounded-[8px]  mt-5">
          <div className="">
            <img src={card1} alt="" /> &nbsp;
            <p>Soofa</p>
            <p className="flex items-center gap-1">
              London UK{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="12"
                viewBox="0 0 9 12"
                fill="none"
              >
                <path
                  d="M4.375 11.6261C3.58559 10.9527 2.85387 10.2145 2.1875 9.41922C1.1875 8.22485 5.49309e-07 6.44609 5.49309e-07 4.7511C-0.000433137 3.88545 0.255943 3.03912 0.736684 2.31924C1.21742 1.59935 1.90092 1.03826 2.70067 0.706969C3.50042 0.375681 4.38047 0.289089 5.22945 0.458152C6.07843 0.627216 6.85817 1.04433 7.47 1.65672C7.87735 2.06226 8.20022 2.54456 8.41993 3.0757C8.63964 3.60685 8.75183 4.1763 8.75 4.7511C8.75 6.44609 7.5625 8.22485 6.5625 9.41922C5.89613 10.2145 5.16441 10.9527 4.375 11.6261ZM4.375 2.8761C3.87772 2.8761 3.40081 3.07364 3.04918 3.42527C2.69754 3.7769 2.5 4.25381 2.5 4.7511C2.5 5.24838 2.69754 5.72529 3.04918 6.07692C3.40081 6.42855 3.87772 6.6261 4.375 6.6261C4.87228 6.6261 5.3492 6.42855 5.70083 6.07692C6.05246 5.72529 6.25 5.24838 6.25 4.7511C6.25 4.25381 6.05246 3.7769 5.70083 3.42527C5.3492 3.07364 4.87228 2.8761 4.375 2.8761Z"
                  fill="black"
                  fillOpacity="0.5"
                />
              </svg>
            </p>
          </div>
        </div>
       
      </div>
      <div className="flex gap-2">
        <div className=" bg-white shadow-md p-[8px] rounded-[8px]  mt-5 ">
          <div className="">
            <img src={card1} alt="" /> &nbsp;
            <p>Soofa</p>
            <p className="flex items-center gap-1">
              London UK{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="12"
                viewBox="0 0 9 12"
                fill="none"
              >
                <path
                  d="M4.375 11.6261C3.58559 10.9527 2.85387 10.2145 2.1875 9.41922C1.1875 8.22485 5.49309e-07 6.44609 5.49309e-07 4.7511C-0.000433137 3.88545 0.255943 3.03912 0.736684 2.31924C1.21742 1.59935 1.90092 1.03826 2.70067 0.706969C3.50042 0.375681 4.38047 0.289089 5.22945 0.458152C6.07843 0.627216 6.85817 1.04433 7.47 1.65672C7.87735 2.06226 8.20022 2.54456 8.41993 3.0757C8.63964 3.60685 8.75183 4.1763 8.75 4.7511C8.75 6.44609 7.5625 8.22485 6.5625 9.41922C5.89613 10.2145 5.16441 10.9527 4.375 11.6261ZM4.375 2.8761C3.87772 2.8761 3.40081 3.07364 3.04918 3.42527C2.69754 3.7769 2.5 4.25381 2.5 4.7511C2.5 5.24838 2.69754 5.72529 3.04918 6.07692C3.40081 6.42855 3.87772 6.6261 4.375 6.6261C4.87228 6.6261 5.3492 6.42855 5.70083 6.07692C6.05246 5.72529 6.25 5.24838 6.25 4.7511C6.25 4.25381 6.05246 3.7769 5.70083 3.42527C5.3492 3.07364 4.87228 2.8761 4.375 2.8761Z"
                  fill="black"
                  fillOpacity="0.5"
                />
              </svg>
            </p>
          </div>
        </div>
        <div className=" bg-white shadow-md p-[8px] rounded-[8px]  mt-5">
          <div className="">
            <img src={card1} alt="" /> &nbsp;
            <p>Soofa</p>
            <p className="flex items-center gap-1">
              London UK{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="12"
                viewBox="0 0 9 12"
                fill="none"
              >
                <path
                  d="M4.375 11.6261C3.58559 10.9527 2.85387 10.2145 2.1875 9.41922C1.1875 8.22485 5.49309e-07 6.44609 5.49309e-07 4.7511C-0.000433137 3.88545 0.255943 3.03912 0.736684 2.31924C1.21742 1.59935 1.90092 1.03826 2.70067 0.706969C3.50042 0.375681 4.38047 0.289089 5.22945 0.458152C6.07843 0.627216 6.85817 1.04433 7.47 1.65672C7.87735 2.06226 8.20022 2.54456 8.41993 3.0757C8.63964 3.60685 8.75183 4.1763 8.75 4.7511C8.75 6.44609 7.5625 8.22485 6.5625 9.41922C5.89613 10.2145 5.16441 10.9527 4.375 11.6261ZM4.375 2.8761C3.87772 2.8761 3.40081 3.07364 3.04918 3.42527C2.69754 3.7769 2.5 4.25381 2.5 4.7511C2.5 5.24838 2.69754 5.72529 3.04918 6.07692C3.40081 6.42855 3.87772 6.6261 4.375 6.6261C4.87228 6.6261 5.3492 6.42855 5.70083 6.07692C6.05246 5.72529 6.25 5.24838 6.25 4.7511C6.25 4.25381 6.05246 3.7769 5.70083 3.42527C5.3492 3.07364 4.87228 2.8761 4.375 2.8761Z"
                  fill="black"
                  fillOpacity="0.5"
                />
              </svg>
            </p>
          </div>
        </div>
       
      </div>

      <Link to={'/'} className="flex justify-center mt-5 underline ">
        Explore More
      </Link>
    </div>
  );
}
