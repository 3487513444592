import React from 'react'
import MyImg from '../../../../accests/images/Frame.png'
const CheckOutMainCard = (props) => {
    return (
        <div className='w-full flex justify-center mb-2 '>
            <div className='w-full md:w-[587px] md:h-[249px] flex justify-between'>

                <div><img className='h-[156px] w-[100px] md:h-full md:w-[155.63px]' src={MyImg} alt="" /></div>

                <div className='ml-[17px] flex justify-between w-[65%] md:w-[414.38px] text-[18px] h-8 md:text-xl font-semibold'>
                    <div className=''>{props.data.title}
                        <div className='text-xs gap-[4px] md:text-base font-light'>New</div>
                        <div className='text-xs gap-[4px] md:text-base font-light'>Size:L</div>
                    </div>
                    <div>$ {props.data.totalPrice}.0</div>  
                </div>

            </div>
        </div>
    );
}

export default CheckOutMainCard;
