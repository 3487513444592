import cloneDeep from "clone-deep";
import { getCookie, setCookie } from "services/session/cookies";
import { createSlice } from "@reduxjs/toolkit";
import { getCookiesByName } from "utils/helpers/common";

const initialState = {
    accessToken: getCookie("accessToken") ?? "",
    refreshToken: getCookie("refreshToken") ?? "",
    user: // getCookiesByName("user", true) ?? "",
    {
        email: "abaidmalik243@gmail.com",
        dob: "12-12-2003",
        accountType: 'personal',
        businessPhoneNumber: {
            countryCode: '+92',
            phoneNumber: '3067134632',
            completePhoneNumber: '+923067134632'
        },
    },

    selectAccountInfo: {
        accountType: 'personal',
        // dob: "12-12-2003",
        // businessName: '',
        // businessRegistrationNumber: '',
        // businessPhoneNumber: {
        //     countryCode: '+92',
        //     phoneNumber: '3067134632',
        //     completePhoneNumber: '+923067134632'
        // },
        // profileImage: ''
    },

};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAccessToken: (state, action) => {
            const s1 = cloneDeep(state);
            const { accessToken, refreshToken } = action?.payload;

            if (accessToken) {
                s1.accessToken = accessToken;
                setCookie("accessToken", accessToken);
            }
            if (refreshToken) {
                s1.refreshToken = refreshToken;
                setCookie("refreshToken", refreshToken);
            }
            return s1;
        },
        setLoginUserDetails: (state, action) => {
            const s1 = cloneDeep(state);
            const { user, accessToken } = action?.payload;

            if (user) {
                s1.user = user;
                setCookie("user", user);
            }
            if (accessToken) {
                s1.accessToken = accessToken;
                setCookie("accessToken", accessToken);
            }
            return s1;
        },
        setUserInfo: (state, action) => {
            const s1 = cloneDeep(state);
            const { user } = action?.payload;
            if (user) {
                s1.user = user;
                setCookie("user", user);
            }
            return s1;
        },
        setSelectAccountInfo: (state, action) => {
            let s1 = cloneDeep(state);

            s1 = {
                ...s1,
                selectAccountInfo: {
                    ...s1.selectAccountInfo,
                    ...action?.payload
                }
            }
            return s1;
        },
    },
});
export const {
    setAccessToken,
    setLoginUserDetails,
    setSelectAccountInfo,
    setUserInfo

} = authSlice.actions;

const authReducer = authSlice.reducer;

export default authReducer;
