import React from 'react'
import Slider from "react-slick"
import prome1 from "../../../../accests/images/itempromo1.png"
import prome2 from "../../../../accests/images/itempromo2.png"
import addprome from "../../../../accests/iconsSvg/addpromo.svg"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

export default function PromocCraousel() {

 
        var settings = {
          dots: false,
          infinite: false,
          speed: 500,
          slidesToShow: 6,
          slidesToScroll: 1,
          initialSlide: 0,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
                infinite: false,
                dots: false
              }
            },
            {
              breakpoint: 710,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            }
          ]
        };
        const navigate = useNavigate();


  return (
    <>
    <div  onClick={() => navigate('/')} className="flex items-center text-[#BF1017] text-[31px] font-[700] cursor-pointer md:hidden my-4">
          SellOut
        </div>
    <div className='flex justify-center' >
    <div className='w-[90vw] md:w-[80vw] lg:w-[35vw] xl:w-[40vw]'>
    <Slider {...settings} >
      <div className='focus:outline-none'>
        <img src={addprome} alt="" className='cursor-pointer ' />
      </div>
      <div  className='focus:outline-none'>
      <img src={prome1} alt="" />
      </div>
      <div  className='focus:outline-none'>
      <img src={prome2} alt="" />
      </div>
      <div  className='focus:outline-none'>
      <img src={prome2} alt="" />
      </div>
      <div  className='focus:outline-none'>
      <img src={prome2} alt="" />
      </div >
      <div  className='focus:outline-none'>
      <img src={prome2} alt="" />
      </div>
      <div  className='focus:outline-none'>
      <img src={prome2} alt="" />
      </div>
      <div  className='focus:outline-none'>
      <img src={prome2} alt="" />
      </div>
      <div  className='focus:outline-none'>
      <img src={prome2} alt="" />
      </div>
    
    </Slider>
  </div>
  
  </div>
  </>
  )
}