
const categoryList = [
    {
        _id: 1,
        name: 'Items',
        abbr: 'IT',
        icon: ''
    },
    {
        _id: 2,
        name: 'Clothes & Footwear',
        abbr: 'CF',
        icon: ''
    },
    {
        _id: 3,
        name: 'Vehicles',
        abbr: 'VE',
        icon: ''
    },
    {
        _id: 4,
        name: 'Food and Drink',
        abbr: 'FD',
        icon: ''
    },
    {
        _id: 5,
        name: 'Properties for sale/rent',
        abbr: 'PS',
        icon: ''
    },
    // {
    //     _id: 6,
    //     name: 'Services',
    //     abbr: 'SE',
    //     icon: ''
    // },
    {
        _id: 7,
        name: 'Pets',
        abbr: 'PE',
        icon: ''
    }
]

module.exports = {
    categoryList
}