import { configureStore } from "@reduxjs/toolkit";
import Slices from "./FunctionSlice/Slice";
import CheckOutSlices from "./FunctionSlice/CheckOutSlice";

const store = configureStore ({
    reducer:{
        functions: Slices.reducer,
        CheckOutSlice: CheckOutSlices.reducer
    }
})

export default store 