
import { createSlice } from "@reduxjs/toolkit";


const Slices = createSlice({
    name: "functions",
    initialState: {
        functions: [],
        loading: false,
        email: "abaid@gmail.com",
        userForget: {
            email: 'abaid@gmail.com',
            phoneNumber: '+923067134632'
        },
        phoneNumber: "+923067134632",
        error: null,
    },
    reducers: {
        firstFunction(state, action) {
        },

        emailHandle(state, action) {
            state.email = action.payload

        },
        phoneHandle(state, action) {
            state.phoneNumber = action.payload
        },

        ForgetHandle(state, action) {
            state.userForget = action.payload
        }

    },

})

export default Slices;

export const {
    firstFunction,
    emailHandle,
    phoneHandle,
    ForgetHandle
} = Slices.actions;