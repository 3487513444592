import React from 'react';
import { Button } from '@material-tailwind/react';

const SellerDashbordProductCrd = (props) => {
    const cardStar = 5
    return (
        <div className='max-w-[1440px] m-auto'>
        <div className="product-box ">
            <img src={props.itemCard.image} className="w-full rounded-xl" alt="" /> 
            <div className='flex justify-between items-center'>
                <div>  
                <p className="product-title">
                    {props.itemCard.title}
                </p>
                <div className="flex ">
                  
                    <small className="product-rating">
                        {props.itemCard.rating}
                    </small>
                    {
                        [...Array(cardStar)].map((_, i) => (
                        <span key={i} className={` ${i === 0 ? 'product-rating-star ml-1' : 'product-rating-star'}`}>

                            {i < +props.itemCard.rating ? '★' : '☆'}

                        </span>
                        ))
                        
                    }
                    {/* <span className="product-rating-star ml-1">
                        &#9733;
                    </span>
                    <span className="product-rating-star">
                        &#9733;
                    </span>
                    <span className="product-rating-star">
                        &#9733;
                    </span>
                    <span className="product-rating-star">
                        &#9733;
                    </span>
                    <span className="product-rating-star">
                        &#9734;
                    </span> */}
          
                </div>
                <p className="product-price">{props.itemCard.price}</p>
            </div>
            <div >
            <Button className='shadow-none normal-case text-[#00B49E] border border-[#00B49E] w-[104px] h-[32px] text-base font-semibold flex justify-center items-center my-2'>Promot</Button>
            <Button className='shadow-none normal-case text-[#BF1017] border border-[#BF1017] w-[104px] h-[32px] text-base font-semibold flex justify-center items-center'>Edit</Button>
            </div></div>
        </div>
        </div>
    );
}

export default SellerDashbordProductCrd;
