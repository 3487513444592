import React from 'react'
import Home from './Home'
import About from './About/About'
import FourZeroFour from './404'
import { Routes, Route } from 'react-router-dom'
import CartScreen from './CartScreen/CartScreen'
import SellerStoreProfile from './SellerDashbord'
import Settings from './Settings/Settings'
import CheckoutCompleted from './CheckoutCompleted'
import Messenger from './Messenger/Messenger'
import CheckOut from './CheckOut/CheckOut'


function index() {
  return (
    <main>
    <Routes>
      <Route path='/'>
          <Route  index element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/cart' element={<CartScreen/>}/>
          <Route path='/settings' element={<Settings/>}/>
          <Route path='/checkoutcompleted' element={<CheckoutCompleted/>}/>
          <Route path='/SellerStoreProfile' element={<SellerStoreProfile/>}/>
          <Route path='/messenger' element={<Messenger/>}/>
          <Route path='/checkOut' element={<CheckOut/>}/>

          <Route path='/*' element={<FourZeroFour/>}/>
      </Route>
  </Routes>
  </main>
  )
}

export default index;