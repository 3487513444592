import SelectAccountHeader from "pages/Frontend/Components/AuthNavbar/AuthNavbar";
import React from "react";

function SelectAccountLayout({ children , showHeader}) {
    return (
        <>
        {showHeader && 
          <SelectAccountHeader />
        
        }
        <div className="flex items-center justify-center max-h-100  md:min-h-screen">
            <div className="lg:w-[65%] w-full mx-3 flex items-center justify-center">
            {children} 
            </div>
            </div>
        </>
    );
}

export default SelectAccountLayout;
