import React from 'react';
import profile2 from '../../../../accests/images/Sseller.png'
import messengerPhone from '../../../../accests/images/messenger_phone.png'
import messengerCamera from '../../../../accests/images/messenger_camera.png'
import smile from '../../../../accests/images/smile.png'
import send from '../../../../accests/images/send.png'
import actionImage from '../../../../accests/images/octicon_image.png'
const MessengerChatBox = () => {
    return (
        <div className='shadow-md rounded-[8px] p-[12px]'>
            <div>
                <div className='flex shadow-md px-2 py-3 mb-3 rounded-md shadow-gray-300 justify-between'>
                    <div className='flex items-center'>
                        <img width={60} src={profile2} alt="" />

                        <div className='ml-2'>
                            <p className='font-bold text-[18px] '>Chirs Howl</p>
                            <p className=' text-[12px] text-gray-400 '>Tab here to open profile</p>
                        </div>
                    </div>
                    <div className=' mt-2 mr-2 flex items-center'>
                        <div>  <img width={25} height={25} src={messengerCamera} alt="" /></div>
                        <div className='ml-5'><img width={25} height={25} src={messengerPhone} alt="" /></div>

                    </div>
                </div>
            </div>
            <div className='h-[60vh] flex justify-center items-center'>
                <div>
                    <p className='font-bold text-[26px] text-gray-500'>Say Hi</p>
                    <p className='text-gray-500'>and start a conservation.</p>
                </div>

            </div>
            <div className='flex gap-[12px]'>
                <div className='bg-[#EEE] flex p-[10px] gap-[10px] align-middle rounded-[8px] w-full'>
                    <img className='w-[24px] h-[24px]' src={smile} alt="" />
                    <input placeholder='Your message here' className='w-full bg-transparent outline-none' type="text" />
                    <img className='w-[24px] h-[24px]' src={actionImage} alt="" />
                </div>
                <div className='bg-[#EEE] w-[40px] px-[10px] py-[12px] rounded-[8px] flex justify-center items-center'>
                    <img className='w-[24px] h-[24px]' src={send} alt="" />

                </div>
            </div>
        </div>
    );
}

export default MessengerChatBox;
