import cloneDeep from "clone-deep";
import { createSlice } from "@reduxjs/toolkit";
import { categoryList } from "pages/Frontend/Categories/dummyData";

const initialState = {
    postList: [],
    categoryList: categoryList || []
};

const postSlice = createSlice({
    name: "post",
    initialState,
    reducers: {
        getPostsList: (state, action) => {
            const s1 = cloneDeep(state);
            // debugger
            s1.postList = action?.payload;
            return s1;
        },
        getCategoryList: (state, action) => {
            const s1 = cloneDeep(state);
            s1.categoryList = action?.payload;
            return s1;
        },
    },
});
export const {
    getPostsList,
    getCategoryList,

} = postSlice.actions;

const postReducer = postSlice.reducer;

export default postReducer;
