import React from 'react'
import { Link } from 'react-router-dom';

function AuthLayoutFooter() {
    return (
        <div className='text-center lg:text-lg md:text-md text-xs  py-5 text-[#777777]  text-thin w-full'>
            <div className='flex flex-wrap justify-center'>
                <Link to={''} className='md:m-3 m-1'>Login</Link>
                <Link to={''} className='md:m-3 m-1'>Sign up</Link>
                <Link to={''} className='md:m-3 m-1'>Bids</Link>
                <Link to={''} className='md:m-3 m-1'>Antiques & Collections</Link>
                <Link to={''} className='md:m-3 m-1'>Arts&Craft</Link>
                <Link to={''} className='md:m-3 m-1'>Garage Sale</Link>
                <Link to={''} className='md:m-3 m-1'>New Products</Link>

            </div>
            <div><p className='text-center md:mt-3 mt-2'>&copy; 2023 SellOut</p> </div>
        </div>
    )
}

export default AuthLayoutFooter;