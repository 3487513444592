import React, { useState, useEffect, useRef } from "react";
import arrow from "../../../../accests/iconsSvg/arrow.svg"
import { useNavigate } from "react-router-dom";
import man from "../../../../accests/images/man.png";
import menu from "../../../../accests/images/menu.svg";
import chat from "../../../../accests/images/chat.svg";
import forward from "../../../../accests/images/forward.svg";
import bookmark from "../../../../accests/images/bookmark.svg";
import ReportedModal from "./ReportedModal";
import { useDispatch, useSelector } from "react-redux";
import { getPostsList } from "redux/slices/postSlice";
import { APICallMethod } from "services/api/auth";
import { ENDPOINTS, REQUEST_TYPES } from "utils/constants/url";
import { toast } from "react-toastify";
import { getTimeAgo, setTextLimit } from "utils/helpers/common";

const ProductCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Dropdown
  const [isOpen, setIsOpen] = useState(false);
  // MODAL
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const [state, setState] = useState({
  //   postList: []
  // });

  const postList = useSelector(state => state.post.postList);


  const reportOptions = [
    {
      id: 1,
      title: 'Its a spam',
      active: true,
    },
    {
      id: 2,
      title: 'Sale of Illegal',
      active: true,
    },
    {
      id: 3,
      title: 'Scam',
      active: true,
    },
    {
      id: 4,
      title: 'Hate speech',
      active: true,
    },
    {
      id: 5,
      title: 'Voilence or dangerous',
      active: true,
    },
    {
      id: 6,
      title: 'Bullying or harrasment',
      active: true,
    },
    {
      id: 7,
      title: 'Intellectual Property Violation',
      active: true,
    },
    {
      id: 8,
      title: 'Self Injury or Sucide',
      active: true,
    },
    {
      id: 9,
      title: 'False Information',
      active: true,
    }
  ]

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const modalRef = useRef(null);

  // useEffect(() => {
  //   setState(postList);
  // }, [postList]);

  const apiCall = async () => {
    const { data, isError, message } = await APICallMethod(
      REQUEST_TYPES.GET,
      `${ENDPOINTS.GET_ALL_POSTS}?pageSize=${5}&pageNo=${1}`
    );
// debugger
    if (isError) {
      toast.error(message);
      return false
    }
    return data;
  }

  useEffect(() => {
    const fetchData = async () => {
      const resp = await apiCall();
// debugger
      if (resp !== false) {
        dispatch(getPostsList(resp));
      }
    };

    // fetchData();
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeDropdown(); // Close the dropdown when clicking outside
      }
    }

    // Add event listener to detect clicks outside the modal
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup by removing the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>

      <div>
        <div className=" justify-center flex flex-col items-center">
          {!(Array.isArray(postList) && postList?.length) ?
<div className="border  w-[90%] m-[50px] rounded-lg  h-[300px] text-2xl font-[500] flex justify-center items-center text-center ">
  Empty List
</div>
          :
          postList?.map(item => {
            return (
              <div className=" md:w-[90vw]  w-[80vw] lg:w-[35vw] xl:w-[44vw] bg-white h-fit shadow-md shadow-[rgba(0, 0, 0, 0.08)] rounded-xl py-5 mb-5">
                <div className="flex items-center gap-2 justify-between px-[12px] pb-3">
                  <div className="flex items-center gap-2">
                    <img
                      src={man}
                      alt="img"
                      className="w-[36px] h-[36px] cursor-pointer"
                      onClick={() => navigate("/SellerStoreProfile")}
                    />
                    <div className="">
                      <h1 className="text-[16px] font-bold text-black text-opacity-70">
                        Clay Mcintyre
                      </h1>
                      <p className="text-[#777777] text-[10px] font-light">
                        {getTimeAgo(item?.createdAt)}
                      </p>
                    </div>
                  </div>

                  <div className="relative">
                    <button
                      onClick={toggleDropdown}
                      className="w-[22px] h-[22px] cursor-pointer"
                    >
                      <img src={menu} alt="Menu" />
                    </button>

                    {isOpen && (
                      <div ref={modalRef}>
                        <div
                          id="dropdownHover"
                          className="z-10 absolute bg-gray-200 divide-y divide-gray-100 rounded-lg shadow-xl dark:bg-gray-700 w-[60vw] md:w-[40vw] top-10 lg:w-[30vw] xl:w-[40vw] right-0"

                        >
                          <ul
                            className="py-2 text-sm text-gray-700 dark:text-gray-200"
                            aria-labelledby="dropdownHoverButton">

                            {reportOptions?.map(item => {
                              return (<div className="flex justify-between hover:bg-gray-100 cursor-pointer" onClick={openModal} >
                                <li key={item?.id} className="block px-4 py-2 dark:hover:bg-gray-600 dark:hover:text-white">
                                  {item?.title}
                                </li>
                                <img src={arrow} alt="" />
                              </div>);
                            })}

                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <img src={item?.imageUrls[0]} className="w-[100%] h-[450px] pb-3 object-cover" alt=""/>

                <div className="flex justify-between pb-3">
                  <div className="flex gap-3">
                    <img src={chat} className="p-3" alt=""/>
                    <img src={forward} className="p-3" alt=""/>
                  </div>
                  <div>
                    <img src={bookmark} className="p-3" alt=""/>
                  </div>
                </div>

                <div className="px-2 pb-6">
                  <div className="flex justify-between items-center">
                    <h3 className="text-[18px] font-normal text-[#03071E]">
                      {item?.name || 'N/A'}
                    </h3>
                    <h3 className="text-[22px] font-bold text-[#03071E]">${item?.price || 'N/A'}</h3>
                  </div>
                  <p className="text-[16px] font-light text-[#252525]">
                    {setTextLimit(item?.description) || 'N/A'}
                  </p>
                </div>

                <div className="flex gap-6 justify-center w-[95%] m-auto">
                  <button className="lg:px-[5px] w-[48%] sm:w-[45%] md:px-[10px] sm:px-5 p-2 sm:py-2  bg-[#BF1017] rounded-lg text-[#FFF]">
                    Buy Now
                  </button>
                  <button className="lg:px-[5px] w-[48%] sm:w-[45%] md:px-[10px] sm:px-5 p-2 sm:py-2  bg-[#BF1017] rounded-lg text-[#FFF]">
                    Make Offer
                  </button>
                </div>
                <div className="flex justify-center">
                  <button className="lg:px-[5px] w-[90%] md:px-[10px] px-5 py-2 border-[2px] border-[#BF1017] rounded-lg font-[700] text-[#BF1017] mt-3 m-auto">
                    Add to basket
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>



      <div id="default-modal" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div class="relative p-4 w-full max-w-2xl max-h-full">
          {/* <!-- Modal content -->  */}
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/* {/* <!-- Modal header -->  */}
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                Terms of Service
              </h3>
              <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            {/* {/* <!-- Modal body -->  */}
            <div class="p-4 md:p-5 space-y-4">
              <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.
              </p>
              <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.
              </p>
            </div>
            {/* {/* <!-- Modal footer -->  */}
            <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button data-modal-hide="default-modal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">I accept</button>
              <button data-modal-hide="default-modal" type="button" class="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Decline</button>
            </div>
          </div>
        </div>
      </div>
      <ReportedModal isOpen={isModalOpen} handleModal={closeModal} modalRef={modalRef} />


    </>
  );
}

export default ProductCard;