import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from 'react-toastify';
import { serverURL } from "config";


export const getSignupData = createAsyncThunk(
  "signup/getSignupData",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${serverURL}/signup`,
        {
          ...data,
          role: "user",
          accountType: "personal"
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response?.data?.isSuccess) {
        toast.error(response.data.message);
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  datasignup: null,
  status: "blocked",
  loading: false,
};

const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    addDetails: (state, action) => {
      state.datasignup = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSignupData.fulfilled, (state, action) => {
      state.datasignup = action.payload;
      state.loading = false;
    });
    builder.addCase(getSignupData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSignupData.rejected, (state) => {
      state.loading = false;
      toast.error("Something went wrong");
    });
  },
});

export const { addDetails } = signupSlice.actions;
export default signupSlice.reducer;
