import React from "react";

import {
    Input,
  
    Typography,

} from "@material-tailwind/react"; 



const CheckOutForm = ({ getForm, valid }) => { 
    return (
        <>
            <form className="h-[370px] pt-[14px] w-full flex-col md:w-[587px] md:h-[400px] ">
                <div className="h:[90px] md:h-[90px] md:w-full">
                    <div className="h:[90px] md:h-[90px] w-full ">
                        <Typography
                            className=" h-[35px] text-base font-bold md:font-medium md:text-[18px]"
                        >
                            Address Line 1
                        </Typography>
                        <Input type="text" onChange={getForm} name="addressLineOne" className="h-[48px]  rounded-[8px]  md:h-12 rounded-[0.5px] p-2 border-[#777777] md:rounded "
                        />
                        <div className="h-5 ">
                            {
                                valid.addressOne === 1 &&
                                <p className='text-red-500 text-sm'>please enter address</p>
                            }

                        </div>
                    </div>
                    <div className="h:[95px] md:h-[95px] w-full  mt-[8px]">
                        <Typography className=" h-[35px] text-base font-bold md:font-medium md:text-[18px]">
                            Address Line 2
                        </Typography>
                        <Input type="text" onChange={getForm} name="addressLineTwo" className="rounded-[8px] h-[48px]  md:h-12 rounded-[0.5px] p-2 border-[#777777] md:rounded" />
                        {
                            valid.addressTwo === 1 &&
                            <p className='text-red-500 text-sm'>please enter address</p>
                        }
                    </div>
                    <div className="my-4 flex h:[90px] md:h-[90px] items-center justify-between gap-2  mt-[8px]">
                        <div className="w-[50%]">
                            <Typography

                                className="mb-2 text-base font-bold md:font-medium md:text-[18px]"
                            >
                                State
                            </Typography>
                            <Input
                                type="text" onChange={getForm} name="state" className="rounded-[0.5px] h-[48px]  rounded-[8px] border-[#777777] p-2 focus:!border-t-gray-900 md:rounded md:h-12 md:w-[289px]"
                            />
                            <div className="h-5">
                                {
                                    valid.state === 1 &&
                                    <p className='text-red-500 text-sm'>please enter address</p>
                                }
                            </div>
                        </div>
                        <div className="w-[50%]">
                            <Typography

                                className="mb-2 text-base font-bold md:font-medium md:text-[18px]"
                            >
                                City
                            </Typography>
                            <Input
                                type="text" onChange={getForm} name="city" className="rounded-[8px] h-[48px]  rounded-[0.5px] border-[#777777] p-2 md:rounded md:h-12 md:w-[289px]"
                            />
                            <div className="h-5">
                                {
                                    valid.city === 1 &&
                                    <p className='text-red-500 text-sm'>please enter address</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="my-4 flex items-center md:h-[90px] justify-between gap-2  mt-[8px]">
                        <div className="w-[50%]">
                            <Typography

                                className=" mb-2 text-base font-bold md:font-medium md:text-[18px]"
                            >
                                Country
                            </Typography>
                            <Input

                                type="text" onChange={getForm} name="country" className="rounded-[8px] h-[48px]  rounded-[0.5px] border-[#777777] p-2 md:rounded md:h-12 md:w-[289px]"

                            />
                            <div className="h-5">
                                {
                                    valid.country === 1 &&
                                    <p className='text-red-500 text-sm'>please enter address</p>
                                }
                            </div>
                        </div>
                        <div className="w-[50%]">
                            <Typography

                                className="mb-2 text-base font-bold md:font-medium md:text-[18px]"
                            >
                                Zip Code
                            </Typography>
                            <Input
                                type="text" onChange={getForm} name="zipCode" className="rounded-[8px] h-[48px]  rounded-[0.5px] border-[#777777] p-2 md:rounded md:h-12 md:w-[289px]"

                            />
                            <div className="h-5">
                            {
                                valid.zipCode === 1 &&
                                <p className='text-red-500 text-sm'>please enter address</p>
                            }
                            </div>
                        </div>
                    </div>

                </div>
            </form></>
    );
}

export default CheckOutForm;
