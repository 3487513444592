import React, { useState } from 'react';
import Iconsbar from 'pages/Frontend/Components/IconsBar/Iconsbar';

function DashboardNavbar() {

    return (
        <>
            <Iconsbar />
        </>
    )
}

export default DashboardNavbar;