import { serverURL } from '../../config';
export const BASE_URL = serverURL || ''

export const ENDPOINTS = {
  GET_IMAGE_URL: `${BASE_URL}/getImageUrl`,
  LOGIN: `${BASE_URL}/signin`,
  AUTHORIZE: `${BASE_URL}/authorised`,
  SIGNUP: `${BASE_URL}/signup`,
  USER_PROFILE: `${BASE_URL}/me`,
  FORGOT_PASS: `${BASE_URL}/forgot-password`,
  RESEND_OTP: `${BASE_URL}/resend-otp`,
  VERIFY_OTP: `${BASE_URL}/verify-otp`,
  RESET_PASS: `${BASE_URL}/reset-password`,
  EDIT_PROFILE: `${BASE_URL}/updateUser`,
  GET_ALL_POSTS: `${BASE_URL}/products/getAll`,
  CREATE_POST: `${BASE_URL}/products/create`,
  GET_ALL_CATEGORIES: `${BASE_URL}/product-categories/getAll`,
  SET_BUSINESS_INFO: `${BASE_URL}/business-info`,
  ACCOUNT_SELECTION: `${BASE_URL}/account-selection`,
}

export const REQUEST_TYPES = {
  DELETE: 'DELETE',
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
}
