import React, { useState, useEffect } from 'react'

// import MainCard from '../../../../accests/iainCard'
import CheckOutMainCard from '../CheckOutMainCard/CheckOutMainCard'
import Paypal from '../../../../accests/images/logos_paypal.png'
import Strip from '../../../../accests/images/logos_stripe.png'
import ApplePay from '../../../../accests/images/la_cc-apple-pay.png'
import Vise from '../../../../accests/images/formkit_visa (1).png'
import MasterCard from '../../../../accests/images/formkit_visa.png'
import America from '../../../../accests/images/American.png'
import { useDispatch, useSelector } from 'react-redux';
import { addTottal } from 'store/FunctionSlice/CheckOutSlice'
const CheckOutStepThree = ({ placeOrder }) => {

    let [allTotalPrice, setAllTotalPrice] = useState(0) 
    let dispatch = useDispatch()

    const totalCards = useSelector((store) => store.CheckOutSlice?.totalCards)


    const calculateTotalPrice = () => {
        if (allTotalPrice === 0) {
            const totalPrice = totalCards?.reduce((accumulator, data) => {
                return accumulator + data.totalPrice;
            }, 0);

            setAllTotalPrice(totalPrice);
        }
    };

    useEffect(() => {
        calculateTotalPrice();
    }, [totalCards]);

    useEffect(() => {
        dispatch(addTottal(allTotalPrice))
    }, [allTotalPrice])

    return (
        <div className='w-full h-[939px] flex justify-center'>
            <div className='w-[95%]  md:w-[603px] md:rounded-xl '>
                <div className='md:gap-y-3.5 flex-col justify-center pt-2 '>
                    <div className=''>
                        {
                            totalCards?.map((data, i) => {
                                return (
                                    <CheckOutMainCard data={data} />
                                )
                            })
                        }
                    </div>

                    <div className='w-[357px]  h-[288px] py-5 md:h-[312px] m-auto md:w-[590px] md:pt-[22px] md:pb-[22px] md:pl-[26px] md:pr-[26px] grid gap-4 place-items-center shadow-[0px_0px_6px_0px_#00000024] rounded-xl flex-col justify-center'>
                        <div className='md:w-[556px] md:h-10  md:pl-[8px] md:pr-[8px] flex justify-center items-center font-semibold text-[20px]'>Select payment method</div>
                        <div className='w-[313px] md:w-[556px] md:h-16  md:pl-[8px] md:pr-[8px] flex justify-center items-center font-semibold text-[10px]'>
                            <div className='w-[104.33px]  md:h-[62px] md:w-[185.33px] flex justify-center'>
                                <div className='flex-col'>
                                    <img src={Paypal} alt="" /> Paypal</div></div>
                            <div className='w-[104.33px] md:h-[62px] md:w-[185.33px] flex justify-center'>
                                <div className='flex-col '>
                                    <img src={Strip} alt="" />
                                    <center>Stripe</center>
                                </div>
                            </div>
                            <div className=' w-[104.33px] md:h-[62px] md:w-[185.33px] flex justify-center '>
                                <div>
                                    <img src={ApplePay} alt="" />
                                    <center>Apple Pay</center>
                                </div>
                            </div>
                        </div>
                        <div className='w-[313px] md:w-[556px] md:h-[15px]  flex justify-between items-center'><hr className=' border-[#E1E1E1] w-[126px] md:w-[247.5px]' />  or   <hr className=' border-[#E1E1E1] w-[126px] md:w-[247.5px]' /> </div>

                        <div className='w-[313px] md:w-[556px] md:h-16  md:pl-[8px] md:pr-[8px] flex justify-center items-center font-semibold text-[10px]'>
                            <div className='w-[104.33px]  md:h-[62px] md:w-[185.33px] flex justify-center'>
                                <div className='flex-col'>
                                    <img src={Vise} alt="" />
                                    <center>Visa</center>
                                </div></div>
                            <div className='w-[104.33px] md:h-[62px] md:w-[185.33px] flex justify-center'>
                                <div className='flex-col '>
                                    <img src={MasterCard} alt="" />
                                    <center>Master Card</center>
                                </div>
                            </div>
                            <div className=' w-[104.33px] md:h-[62px] md:w-[185.33px] flex-col justify-center'>
                                <div className='flex justify-center mt-1'>
                                    <img src={America} alt="" />
                                </div>
                                <center className='mt-2' >American Express</center>
                            </div>
                        </div>

                        {/* </div> */}
                    </div>

                    <div className='w-[345px] md:h-[126px]  md:w-[590px] border-b m-auto grid gap-4 place-items-center'>
                        <div className='md:h-[22px] w-full flex justify-between'>
                            <div className='text:[14px] md:text-[18px] font-normal'>Subtotal (1 item)</div>
                            <div className='text:[14px] md:text-[18px] font-medium'>${allTotalPrice}.0</div>
                        </div>
                        <div className='md:h-[30px] w-full flex justify-between'>
                            <div className='text-[14px] md:text-[18px] font-normal' >Protection Fee</div>
                            <div className=' text-[14px] md:text-[18px] font-medium'>$1.00</div></div>
                        <div className='md:h-[30px] w-full flex justify-between'>
                            <div>Postage</div>
                            <div className='text-[18px] font-medium'>Free</div>
                        </div>

                    </div>

                    <div className='text-[18px]  m-auto md:h-[27px] md:w-[590px] w-full flex justify-between md:text-[22px] font-semibold'>
                        <div>Order total</div>
                        <div>${allTotalPrice}.0</div>
                    </div>

                    <div className='md:h-[82px] md:w-[587px] m-auto flex justify-center'>
                        <div className='flex-col'>
                            <div className='text-[16px] font-normal'>How do you like our checkout ?</div>
                            <div className='flex justify-center font-semibold border-b-2'> Give us feedback</div>
                        </div>
                    </div>

                    <div onClick={placeOrder} className='w-[357px] m-auto cursor-pointer mb-6 border-orage-600 h-12 md:w-[587px] bg-[#BF1017] rounded-lg text-[18px] text-[white] font-semibold flex justify-center items-center'>
                        Confirm
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CheckOutStepThree;
