import { apiCaller } from "../middleware/api-caller";

const getResponseFormat = (myJson) => {
return (myJson?.response?.data ? myJson?.response?.data :
  (myJson?.data ? myJson?.data : myJson)
) ?? {};
}

export const loginAPI = async (method, url, data) => {
  const myJson = await apiCaller({ method, url, data });
  const resp = getResponseFormat(myJson);
  if (resp && (resp.statusCode === 200 || resp.statusCode === 201)) {
    const data = resp?.data ?? [];
    return { data: data, isError: false, message: "" };
  } else {
    const message = resp?.message ?? "Something went wrong";
    return { data: [], isError: true, message };
  }
};


export const authorizeAPI = async (method, url, data) => {
  const myJson = await apiCaller({ method, url, data });
  const resp = getResponseFormat(myJson);
  if (resp && (myJson.status === 200 || myJson.status === 201)) {
    // const data = resp?.data ?? [];
    return { data: resp, isError: false, message: "" };
  } else {
    const message = resp?.message ?? "Something went wrong";
    return { data: [], isError: true, message };
  }
};


export const signupAPI = async (method, url, data) => {
  const myJson = await apiCaller({ method, url, data });
  const resp = getResponseFormat(myJson);
  if (resp && (resp.statusCode === 200 || resp.statusCode === 201)) {
    const data = resp?.data ?? [];
    return { data: data, isError: false, message: "" };
  } else {
    const message = ((Array.isArray(resp?.message) && resp?.message?.length) ? resp?.message[0] :
      (resp?.data?.message ? resp?.data?.message : resp?.message)
    ) ?? "Something went wrong";
    return { data: [], isError: true, message };
  }
};


export const forgotAPI = async (method, url, data) => {
  const myJson = await apiCaller({ method, url, data });
  const resp = getResponseFormat(myJson);
  if (resp && (resp.statusCode === 200 || resp.statusCode === 201)) {
    const data = resp?.data ?? [];
    return { data: data, isError: false, message: "" };
  } else {
    const message = resp?.message ?? "Something went wrong";
    return { data: [], isError: true, message };
  }
};


export const APICallMethod = async (method, url, data) => {
  const myJson = await apiCaller({ method, url, data });
  const resp = getResponseFormat(myJson);
  if (resp && (resp.statusCode === 200 || resp.statusCode === 201)) {
    const data = resp?.data ?? [];
    return { data: data, isError: false, message: "" };
  } else {
    const message = resp?.message ?? "Something went wrong";
    return { data: [], isError: true, message };
  }
};