import React, { useState } from "react";
const Dropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <button
        onClick={toggleDropdown}
        className={`category-dropdown ${
          isOpen ? "rounded-t-lg" : "rounded-lg"
        } `}
      >
        {props.btnHeading}
        <span className={`ml-1 ${isOpen ? "rotate-180" : "rotate-0"}`}>
          <img src={props.arrow} alt=""/>
        </span>
      </button>
      {isOpen && (
        <div className="drop-shutter">
          <div className="flex items-center">
            <input
              type="radio"
              
              class="dropdown-radio"
              name={props.id}
              value="1"
            />
            <button className="dropdown-option">{props.option1}</button>
          </div>
          <div className="flex items-center">
          <input
              type="radio"
              class="dropdown-radio"
              name={props.id}
              value="1"
            />
            <button className="dropdown-option">{props.option2}</button>
          </div>
          <div className="flex items-center">
          <input
              type="radio"
              class="dropdown-radio"
              name={props.id}
              value="1"
            />
            <button className="dropdown-option">{props.option3}</button>
          </div>
          <div className="flex items-center">
          <input
              type="radio"
              class="dropdown-radio"
              name={props.id}
              value="1"
            />
            <button className="dropdown-option">{props.option4}</button>
          </div>
        </div>
      )}
    </div>
  );
};
export default Dropdown;