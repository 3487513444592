import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = 'https://bc6f-2400-adc7-15f-5200-d1f-33f4-66cc-9412.ngrok-free.app/api'

export const getOptCode = createAsyncThunk(
  "optCode/getOptCode",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseUrl}/verifyOtp`,
        { 
          "email":"wishawan@gmail.com", 
          "otp": "982751"
      },
        {
          headers: {
            "Content-Type": "application/json",
          },
          
        }
      )

    

      if (response) {
        return response;
      }

      return thunkAPI.rejectWithValue({
        message: "Request Failed",
      });
    } catch (error) {

      return thunkAPI.rejectWithValue({
        message: error.message,
      });
    }
  }
);

const initialState = {
  codeOPT: null,
  status: "blocked",
  loading: false,
};

const optSlice = createSlice({
  name: "optCode",
  initialState,
  reducers: {
    addOPT: (state, action) => {
      state.codeOPT = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getOptCode.fulfilled, (state, action) => {
      state.codeOPT = action.payload;
      state.loading = false;
    });
    builder.addCase(getOptCode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOptCode.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { addOPT } = optSlice.actions;
export default optSlice.reducer;
