import React from "react";
import {useNavigate } from "react-router-dom";
import setting from "./Svg/setting.svg";
import bell from "./Svg/bell.svg";
import cart from "./Svg/cart.svg";
import search from "./Svg/search.svg";

const Topbar = () => {
  const navigate = useNavigate(); 

  return (
    <nav className="py-4 border-gray-200 border-b-2 sm:mx-[31px]">
      <div className="flex justify-between">
        <div
          onClick={() => navigate("/")}
          className="md:flex items-center text-[#BF1017] text-[31px] font-[700] cursor-pointer hidden w-[336px] pl-3"
        >
          SellOut
        </div>

        <div className="lg:block hidden ">
        <div className="bg-[#F2F2F2] rounded-[8px] p-2 md:flex items-center w-[35vw] xl:w-[40vw] hidden">
          <img src={search} alt="" />
          <input
            type="text"
            placeholder="Search"
            className="bg-transparent border-none focus:outline-none ml-2 w-[100%]"
          />
        </div>
        </div>

        <div className="w-[100%] md:w-[22vw] ">
          <div className="flex items-center lg:mt-0 md:mt-0 mt-4 text-center justify-between md:justify-end">
              
            <div className="flex justify-between ">
              <button className="text-gray-500 bg-[#F2F2F2] mx-2 p-2 rounded-[8px] hover:text-gray-700 md:hidden" onClick={() => navigate("/settings")}>
                <img src={setting} alt="" className="" />
              </button>
              

             
              {/* <button className="text-gray-500 bg-[#F2F2F2] mx-2 p-2 rounded-[8px] hover:text-gray-700 md:hidden">
                <img src={search} alt="" />
               </button> */}
            </div>
            <div className="bg-[#F2F2F2] rounded-[8px] p-2 flex items-center w-[50%]  md:hidden">
          <img src={search} alt="" />
          <input
            type="text"
            placeholder="Search"
            className="bg-transparent border-none focus:outline-none ml-2 w-[100%]"
          />
        </div>
        
            <div className="flex">
              <button className="text-gray-500 bg-[#F2F2F2] p-2 rounded-[8px] hover:text-gray-700 md:block hidden " onClick={() => navigate("/settings")}>
                <img src={setting} alt="" />
              </button>
              <button className="text-gray-500 bg-[#F2F2F2] mx-2 md:mx-6 p-2 rounded-[8px] hover:text-gray-700" onClick={() => navigate("/notification")}>
                <img src={bell} alt="" />
              </button>
              <div className="relative">
                <button className="text-gray-500 bg-[#F2F2F2] mx-2 md:mx-0 p-2 rounded-[8px] hover:text-gray-700" onClick={() => navigate("/cart")}>
                  <img src={cart} alt="" />
                  
                </button>
                <div className="bg-red-500 text-white absolute top-0 right-2 md:right-0 rounded-full w-4 h-4 flex items-center justify-center">
                  0
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      
    </nav>
  );
};

export default Topbar;
